import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from "@angular/material";
import { User } from "src/app/models/user.model";
import { Test } from "src/app/models/test.model";
import { TestService } from "src/app/services/test.service";
import { Exam } from "src/app/models/exam.model";
import { Technology } from "src/app/models/technology.model";
import { TechnologyService } from "src/app/services/technology.service";
import { LevelService } from "src/app/services/level.service";
import { Level } from "src/app/models/level.model";
import { Question } from "src/app/models/question.model";
import { QuestionService } from "src/app/services/question.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidErrorDialogComponent } from "../valid-error-dialog/valid-error-dialog.component";

export interface TestAssignmentData {
  user: User;
  selectedExams: Exam[];
  loading: boolean
}

@Component({
  selector: "app-create-random-test-dialog",
  templateUrl: "./create-random-test-dialog.component.html",
  styleUrls: ["./create-random-test-dialog.component.scss"],
})
export class CreateRandomTestDialogComponent implements OnInit {

  technos: Technology[];
  levels: Level[];
  questions: Question[];
  dataSource: MatTableDataSource<Question>;
  questionsDisplayedColumns = ['id', 'question', 'technology', 'type', 'time'];
  questionsLoaded = false;
  searchFormGroup: FormGroup;


  tests: Test[];
  test: Test;
  loading: boolean = false;
  maxDuration: number;
  maxDurationMinutes: number;
  totalDurationMinutes: number;
  totalDurationSecondes: number;

  constructor(
    public dialogRef: MatDialogRef<CreateRandomTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TestAssignmentData,
    private testService: TestService,

    private technologyService: TechnologyService,
    private levelService: LevelService,
    private questionService: QuestionService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {

    this.getTechnologies();
    this.getLevels();
  }
  ngOnInit() {
    this.searchFormGroup = this.formBuilder.group({
      technos: ['', Validators.required],
      levels: ['', Validators.required],
      duration: ['', Validators.required]
    });
  }

  getTests() {
    return this.tests;
  }

  getQuestions() {
    this.loading = true;
    let technos = this.searchFormGroup.get('technos').value.id;
    let levelsIds = this.searchFormGroup.get('levels').value.map(level => level.id);
    let duration = this.searchFormGroup.get('duration').value;
    this.questionService.getRandomList(levelsIds, technos, duration).subscribe(res => {
      this.loading = false;
      this.questionsLoaded = true;
      this.questions = res;
      this.dataSource = new MatTableDataSource(this.questions);
      let totalEstimatedTime = res.reduce((total, obj) => total + obj.estimatedTime, 0);
      this.totalDurationMinutes = Math.floor(totalEstimatedTime / 60);
      this.totalDurationSecondes = totalEstimatedTime % 60;
    },
      (error) => {
        console.error(error);
        if (error.status == 500) {
          this.dialog.open(ValidErrorDialogComponent, {
            data: { state: -1, title: 'Erreur', description: error.error.message }
          });
          this.questions = [];
        }
        this.loading = false;
        this.questionsLoaded = true;
      })
  }

  getTechnologies() {
    this.technologyService.getList().subscribe(technos => {
      this.technos = technos;
    });
  }
  getLevels() {
    this.levelService.getList().subscribe(levels => {
      this.levels = levels;
    });
  }

  Validate() {
    const questionnaire = new Test();
    questionnaire.questions = this.questions;
    questionnaire.name = 'Test random';
    questionnaire.level = this.questions[0].level;
    this.testService.save(questionnaire).subscribe(res => {
      // Affectation du test au candidat
      const exam = new Exam();
      exam.candidate = this.data.user;
      exam.test = res;
      this.dialogRef.close(exam);
    });
  }


  onSelectionChange() {
    
    if(this.searchFormGroup.get('technos').value && 
      this.searchFormGroup.get('levels').value && 
      this.searchFormGroup.get('levels').value.length > 0) {

      let technos = this.searchFormGroup.get('technos').value.id;
      let levelsIds = this.searchFormGroup.get('levels').value.map(level => level.id);

      this.questionService.getMaxDuration(technos, levelsIds).subscribe(res=> {
        if(res){
          this.maxDuration = res;
          this.maxDurationMinutes= Math.floor(res / 60);
        }
      },
      (error) => {
        console.error(error);
        this.maxDuration = null;
      });
    } 
    else {
      this.maxDuration = null;
      this.maxDurationMinutes = null;
    }
}
}
