import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-change-password',
  templateUrl: './change.password.component.html',
  styleUrls: ['./change.password.component.scss']
})
export class ChangePasswordComponent {
  newPassword: string;
  confirmPassword: string;
  id: number; 

  
  constructor(private apiService: ApiService, private route: ActivatedRoute , private router: Router) {
    this.id = this.route.snapshot.params['id'];
    //console.log('id', this.id)
  }
 
  changePassword() {
    this.id = this.route.snapshot.params['id'];
    // console.log('id', this.id)
    if (this.newPassword === this.confirmPassword) {
    this.apiService.changePassword(this.id, this.newPassword).subscribe(
      (response) => console.log('Mot de passe changé avec succès.', response)
    );
    } else {
      console.error('Les mots de passe ne correspondent pas.');
    }
    this.router.navigate(['login']);
  }
}


