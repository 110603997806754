import { Component, Inject, OnInit } from '@angular/core';
import { PopupService } from './popup.service';
import { TestService } from "src/app/services/test.service";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-popup-to-delete-test',
  templateUrl: './popup-to-delete-test.component.html',
  styleUrls: ['./popup-to-delete-test.component.scss']
})
export class PopupToDeleteTestComponent implements OnInit {

  constructor(private popupService : PopupService, 
              private testService: TestService,
              public dialogRef: MatDialogRef<PopupToDeleteTestComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit():void {}

  supprimerTest(){
    let id = this.popupService.getIdTest();
    this.testService.suppressionLogiqueTest(id).subscribe((_) => {
      this.onNoClick();
    });
  }

  onNoClick(): void {
    let context = this.popupService.getContext();
    context.refreshTestsList();
    this.dialogRef.close();
  }
  
}
