import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { User } from "src/app/models/user.model";
import { Test } from "src/app/models/test.model";
import { TestService } from "src/app/services/test.service";
import { Exam } from "src/app/models/exam.model";

export interface TestAssignmentData {
  user: User;
  selectedExams: Exam[];
  loading: boolean
}

@Component({
  selector: "app-test-assignment-dialog",
  templateUrl: "./test-assignment-dialog.component.html",
  styleUrls: ["./test-assignment-dialog.component.scss"],
})
export class TestAssignmentDialogComponent {
  tests: Test[];
  test: Test;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TestAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TestAssignmentData,
    private testService: TestService,
  ) {
    if (data.loading)
      this.loading = data.loading
    else
      this.testService.getList().subscribe((tests) => {
        this.tests = tests;
      });
  }

  getTests() {
    return this.tests;
  }

  onAddButton() {
    this.loading = true;
    const exam = new Exam();

    exam.candidate = this.data.user;
    exam.test = this.test;
    this.dialogRef.close(exam);
  }
}
