import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {TypeQuestion} from '../models/typeQuestion';


@Injectable({
  providedIn: 'root'
})
export class TypeQuestionService {

  static QCM = new TypeQuestion({id: 'QCM'});
  static SINGLE = new TypeQuestion({id: 'SINGLE'});
//  static CODE = new TypeQuestion({id: 'CODE'});

  constructor(private http: HttpClient) {}

  getList(): Observable<TypeQuestion[]> {
    return this.http.get<TypeQuestion[]>('/PolymatheeWeb/typeQuestions')
      .pipe(map(typeQuestions => {
        typeQuestions.forEach((tq, i, t) => t[i] = new TypeQuestion(tq));
        return typeQuestions;
      }));
  }
}
