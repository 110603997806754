import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Test } from '../models/test.model';
import { Level } from '../models/level.model';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  private static URL = '/PolymatheeWeb/tests';
  time: number;

  constructor(private http: HttpClient) {}
  
  getList(level?: Level): Observable<Test[]> {
    let url = TestService.URL + '?';

    if (level) {
      url += '&level=' + level.id;
    }

    return this.http.get<Test[]>(url)
      .pipe(map(tests => {
        tests.forEach((q, i, t) => t[i] = new Test(q));
        return tests;
    }));
  }

  getById(id: number): Observable<Test> {
    return this.http.get<Test>(TestService.URL + '/' + id)
      .pipe(map(q => {
        return new Test(q);
      }));
  }

  save(test: Test): Observable<Test> {
    return this.http.post<Test>(TestService.URL, test)
      .pipe(map(q => {
        return new Test(q);
    }));
  }

  update(test: Test): Observable<Test> {
    return this.http.put<Test>(TestService.URL + '/' + test.id, test)
      .pipe(map(q => {
        return new Test(q);
    }));
  }

  delete(test: Test): Observable<Test> {
    
    return this.http.delete<Test>(TestService.URL + '/' + test.id);
  }

  suppressionLogiqueTest(id): Observable<Test> {
    return this.http.get<Test>(TestService.URL + '/hidden' + id)
      .pipe(map(q => {
        return new Test(q);
    }));
  }

}
