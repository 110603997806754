import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  private static URL = '/PolymatheeWeb/images';

  constructor(private http: HttpClient) {}

  uploadImage(imageBase64: string, fileName: string): Observable<any> {
    return this.http.post<any>(ImagesService.URL + '/upload/' + fileName, imageBase64);
  }

  getImage(imageName:string){
    let params = new HttpParams();
    params = params.append('name', imageName);
    return this.http.get(ImagesService.URL, {params: params})
  }
}
