import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Level } from '../models/level.model';

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  constructor(private http: HttpClient) {}

  getList(): Observable<Level[]> {
    return this.http.get<Level[]>('/PolymatheeWeb/levels')
      .pipe(map(levels => {
        levels.forEach((l, i, t) => t[i] = new Level(l));
        return levels;
      }));
  }
}
