import { Component, ViewChild, OnInit } from "@angular/core";
import { ResponsiveService } from "src/app/tools/responsive.service";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { Subscription } from "rxjs";
import { Level } from "src/app/models/level.model";
import { LevelService } from "src/app/services/level.service";
import { Question } from "src/app/models/question.model";
import { QuestionService } from "src/app/services/question.service";
import { Technology } from "src/app/models/technology.model";
import { TechnologyService } from "src/app/services/technology.service";
import { LanguageService } from "src/app/language/language.service";

@Component({
  selector: "app-list-question",
  templateUrl: "./list-question.component.html",
  styleUrls: ["./list-question.component.scss"],
})
export class ListQuestionComponent implements OnInit {
  public defaultTechnology = new Technology();
  public defaultLevel = new Level();

  private candidatesRequest: Subscription;
  private questions: Question[];

  dataSource: MatTableDataSource<Question>;

  // Filtres
  technologies: Technology[];
  technology: Technology;

  levels: Level[];
  level: Level;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private questionService: QuestionService,
    private levelService: LevelService,
    private technologyService: TechnologyService,
    private responsiveService: ResponsiveService,
    private translate: LanguageService
  ) {
    this.questions = [];
    this.dataSource = new MatTableDataSource(this.questions);

    this.technology = this.defaultTechnology;
    this.level = this.defaultLevel;

    this.technologyService.getList().subscribe((technologies) => {
      this.technologies = technologies;
    });

    this.loadLevels();
    this.refreshQuestionsList();

    this.translate.addWebLanguageChangeListener(() => {
      this.loadLevels();
      this.refreshQuestionsList();
    });
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (question: Question, filter: string) => {
      filter = filter.trim().toLowerCase();

      const name = question.question.toLowerCase();

      if (name.search(filter) >= 0) {
        return true;
      }

      return false;
    };

    const defaultSort = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (
      question: Question,
      sortHeaderId: string
    ): string | number => {
      let sortAttribut = defaultSort(question, sortHeaderId);

      switch (sortHeaderId) {
        case 'level':
          sortAttribut = question.level.name;
          break;
        case 'technology':
          sortAttribut = question.technology.name;
          break;
      }

      return sortAttribut;
    };
  }

  getQuestionsDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ["name", "nbPoints", "technology", "level", "type", "rateSuccess", "action"]
      : ["name", "nbPoints", "technology", "level", "type", "rateSuccess", "action"];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  loadLevels() {
    this.levelService.getList().subscribe((levels) => {
      this.levels = levels;
    });
  }

  refreshQuestionsList() {
    if (this.candidatesRequest) {
      this.candidatesRequest.unsubscribe();
    }

    this.candidatesRequest = this.questionService
      .getList(
        this.level !== this.defaultLevel ? this.level : null,
        this.technology !== this.defaultTechnology ? [this.technology] : null
      )
      .subscribe((questions) => {
        this.questions.splice(0, this.questions.length);
        this.questions.push(...questions);
        this.dataSource.filter = "";
      });
  }

  resetFilter() {
    this.level = this.defaultLevel;
    this.technology = this.defaultTechnology;
    this.refreshQuestionsList();
  }
}
