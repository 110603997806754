import { ExamSchema } from './interface/exam.model';

export class Exam extends ExamSchema {

    isPassed() {
        return this.dateStartPassage != null && this.dateEndPassage != null;
    }

    getTime() {
        return this.isPassed() ? this.dateEndPassage.getTime() - this.dateStartPassage.getTime() : 0;
    }

    getMinutes() {
        return Math.floor(this.getTime() / 60000);
    }

    getSeconds() {
        return Math.floor(this.getTime() / 1000) - this.getMinutes() * 60;
    }

  

    equals(exam: Exam): boolean {
        return this.id === exam.id;
    }
}
