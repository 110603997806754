import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { Technology } from 'src/app/models/technology.model';
import { TechnologyService } from 'src/app/services/technology.service';

@Component({
  selector: 'app-add-technology',
  templateUrl: './add-technology.component.html',
  styleUrls: ['./add-technology.component.scss']
})
export class AddTechnologyComponent implements OnInit {

  error: string;
  hasError: boolean;
  cnxForm: FormGroup;



  constructor(private formBuilder: FormBuilder, public dialog: MatDialog,
     private translate: LanguageService,private technologyService: TechnologyService,
     private router: Router) { }

  ngOnInit() {

    this.cnxForm = this.formBuilder.group({
      name: ['', Validators.required],
     
    });
  }

  getName() {
    return this.cnxForm.get('name').value;
  }

  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('load.inProgress')}
    });

    const technology = new Technology();
    technology.name = this.getName();
    this.technologyService.save(technology).subscribe(() => {
      dialog.close();

      this.dialog.open(ValidErrorDialogComponent, {
        data: {title: this.translate.getWord('create.valid'), state: 1}
      })
      .afterClosed().subscribe(() => {
        this.router.navigate(['technologies']);
      });
    },
    (error: HttpErrorResponse) => {
      dialog.close();
      this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
      this.hasError = true;
    });
  }


}
