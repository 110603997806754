import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SigninService } from '../signin/signin.service';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(private signinService: SigninService, private router: Router) {}

  canActivate(): boolean {
    if (this.signinService.isAuth()) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
