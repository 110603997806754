import { Status } from '../status.model';
import { Profil } from '../profil.model';
import { User } from '../user.model';
import { Exam } from '../exam.model';
import { SchoolLevel } from '../schoolLevel.model';
import { ProfessionalExperience } from '../professionalExperience.model';

export class UserSchema {
    id?: number;
    name?: string;
    firstname?: string;
    mail?: string;
    phoneNumber?: string;
    password?: string;
    mailValid?: boolean;
    dateCreation?: Date;
    dateLastLogging?: Date;
    status?: Status;
    profil?: Profil;
    recruiter?: User;
    schoolLevel?: SchoolLevel;
    professionalExperience?: ProfessionalExperience;
    note?: string;
    average?: number;
    passages?: Exam[];

    constructor(user: UserSchema = {}) {
        this.id = user.id;
        this.name = user.name;
        this.firstname = user.firstname;
        this.mail = user.mail;
        this.phoneNumber = user.phoneNumber;
        this.password = user.password;
        this.mailValid = user.mailValid;

        if (user.dateCreation) {
            this.dateCreation = new Date(user.dateCreation);
        }

        if (user.dateLastLogging) {
            this.dateLastLogging = new Date(user.dateLastLogging);
        }

        this.status = new Status(user.status);
        this.profil = new Profil(user.profil);

        if (user.recruiter) {
            this.recruiter = new User(user.recruiter);
        }

        if (user.schoolLevel) {
            this.schoolLevel = new SchoolLevel(user.schoolLevel);
        }

        if (user.professionalExperience) {
            this.professionalExperience = new ProfessionalExperience(user.professionalExperience);
        }

        this.note = user.note;
        this.average = user.average;

        if (user.passages) {
            user.passages.forEach((v, i, t) => t[i] = new Exam(v));
            this.passages = user.passages;
        } else {
            this.passages = [];
        }
    }
}
