import { User } from '../user.model';
import { Proposition } from '../proposition.model';
import { Level } from '../level.model';
import { Technology } from '../technology.model';
import { TypeQuestion } from '../typeQuestion';


export class QuestionSchema {
    id?: number;
    question?: string;
    code?: string;
    nbPoints?: number;
    estimatedTime?: number; // in seconds
    userCreation?: User;
    level?: Level;
    technology?: Technology;
    type?: TypeQuestion;
    dateCreation?: Date;
    dateLastUpdate?: Date;
    propositions?: Proposition[];
    rateSuccess?: number;
    imageName?: string;
    checkedToAssignedTest?:boolean ;

    constructor(question: QuestionSchema = {}) {
        this.id = question.id;
        this.type = question.type;
        this.question = question.question;
        this.code = question.code;
        this.nbPoints = question.nbPoints ? question.nbPoints : 0;
        this.estimatedTime = question.estimatedTime ? question.estimatedTime : 0;
        this.imageName = question.imageName;

        if (question.userCreation) {
            this.userCreation = new User(question.userCreation);
        }

        if (question.level) {
            this.level = new Level(question.level);
        }

        if (question.technology) {
            this.technology = new Technology(question.technology);
        }

        if (question.type) {
            this.type = new TypeQuestion(question.type);
        }

        if (question.dateCreation) {
            this.dateCreation = new Date(question.dateCreation);
        }

        if (question.dateLastUpdate) {
            this.dateLastUpdate = new Date(question.dateLastUpdate);
        }

        if (question.propositions) {
            question.propositions.forEach((v, i, t) => t[i] = new Proposition(v));
            this.propositions = question.propositions;
        } else {
            this.propositions = [];
        }

        if (question.rateSuccess >= 0) {
            this.rateSuccess = Math.round(question.rateSuccess * 100) / 100;
        }

    }
}
