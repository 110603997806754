import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SchoolLevel } from '../models/schoolLevel.model';

@Injectable({
  providedIn: 'root'
})
export class SchoolLevelService {

  constructor(private http: HttpClient) {}

  getList(): Observable<SchoolLevel[]> {
    return this.http.get<SchoolLevel[]>('/PolymatheeWeb/schoolLevels')
      .pipe(map(schoolLevels => {
        schoolLevels.forEach((sl, i, t) => t[i] = new SchoolLevel(sl));
        return schoolLevels;
      }));
  }
}
