export class PropositionSchema {
    id?: number;
    num?: number;
    proposition?: string;
    just?: boolean;
    dateLastUpdate?: Date;

    constructor(proposition: PropositionSchema = {}) {
        this.id = proposition.id;
        this.num = proposition.num;
        this.proposition = proposition.proposition;
        this.just = proposition.just;

        if (proposition.dateLastUpdate) {
            this.dateLastUpdate = new Date(proposition.dateLastUpdate);
        }
    }
}
