import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LanguageService } from 'src/app/language/language.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss']
})
export class UserActivationComponent implements OnInit {

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router,
              public dialog: MatDialog, private translate: LanguageService) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.activateAccount(params.key);
    });
  }

  activateAccount(key: string) {
    this.translate.getWordAsyn('activation.inProgress').subscribe(wordInProgress => {
      const dialog = this.dialog.open(LoadDialogComponent, {
        disableClose: true,
        data: {title: wordInProgress}
      });

      this.userService.activationWithKey(key)
        .subscribe((data) => {
          dialog.close();

          const validDialog = this.dialog.open(ValidErrorDialogComponent, {
            data: {title: this.translate.getWord('activation.valid'), description: '', state: 1}
          });

          validDialog.afterClosed().subscribe(() => this.router.navigate(['login']));
        },
        (error: HttpErrorResponse) => {
          dialog.close();

          const validDialog = this.dialog.open(ValidErrorDialogComponent, {
            data: {title: this.translate.getWord('activation.failed'), description: error.error.message === undefined ?
              this.translate.getWord('network.error') : error.error.message, state: -1}
          });

          validDialog.afterClosed().subscribe(() => this.router.navigate(['login']));
        });
    });
  }
}
