import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset.password.component.html',
  styleUrls: ['./reset.password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;

  constructor(private fb: FormBuilder, private emailService: EmailService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.resetForm.valid) {
      const email = this.resetForm.get('email').value;
      this.emailService.sendResetPasswordEmail(email).subscribe(
        (data) => {
               this.snackBar.open('E-mail envoyé avec succès!', 'Fermer', {
             duration: 3000, 
           });
         },
        error => {}
      );
    }
  }
}
