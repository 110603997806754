import { Level } from '../level.model';
import { User } from '../user.model';
import { Question } from '../question.model';
import { Status } from '../status.model';

export class TestSchema {
    id?: number;
    name?: string;
    noteMax?: number;
    time?: number;
    level?: Level;
    userCreated?: User;
    status?: Status;
    dateCreation?: Date;
    dateLastUpdate?: Date;
    fraude?: boolean;
    questions?: Question[];

    constructor(test: TestSchema = {}) {
      if(test){
        this.id = test.id;
        this.name = test.name;
        this.noteMax = test.noteMax;
        this.time = test.time;
     
        if (test.level) {
            this.level = new Level(test.level);
        }

        if (test.userCreated) {
            this.userCreated = new User(test.userCreated);
        }

        if (test.dateCreation) {
            this.dateCreation = new Date(test.dateCreation);
        }

        if (test.dateLastUpdate) {
            this.dateLastUpdate = new Date(test.dateLastUpdate);
        }

        this.fraude = test.fraude;

        if (test.questions) {
            test.questions.forEach((v, i, t) => t[i] = new Question(v));
            this.questions = test.questions;
        } else {
            this.questions = [];
        }
      }
    }
}
