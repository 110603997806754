import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Menu } from 'src/app/models/menu.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent {

  @ViewChild('submenu', {read: ElementRef, static: false}) subMenu: ElementRef;
  @Input() menu: Menu;
  expandable = false;

  constructor() { }

  hasIcon(): boolean {
    return this.menu.icon !== null;
  }

  haveSubMenu(): boolean {
    return this.menu.childrensMenu.length > 0;
  }

  toggleSubMenu() {
    const jSubMenu = $(this.subMenu.nativeElement);
    this.expandable = !this.expandable;

    if (this.expandable) {
      jSubMenu.slideDown();
    } else {
      jSubMenu.slideUp();
    }
  }

  getTextMore() {
    return this.expandable ? 'expand_more' : 'chevron_right';
  }
}
