import { Menu } from '../menu.model';

export class ProfilSchema {
    id?: number;
    name?: string;
    menus?: Menu[];

    constructor(profil: ProfilSchema = {}) {
        this.id = profil.id;
        this.name = profil.name;

        if (profil.menus) {
            profil.menus.forEach((m, i, tab) => tab[i] = new Menu(m));
            this.menus = profil.menus;
        } else {
            this.menus = [];
        }
    }
}
