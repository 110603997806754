export class LanguageSchema {
    name?: string;
    format?: string;
    flag?: string;

    constructor(args: LanguageSchema = {}) {
        this.name = args.name;
        this.format = args.format;
        this.flag = args.flag;
    }
}
