import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService } from './tools/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor(private titleService: Title, private sidenavService: SidenavService) {
    titleService.setTitle('Polymathée');
  }

  getTitle(): string {
    return this.titleService.getTitle();
  }

  getModeMenu() {
    return this.sidenavService.getModeMenu();
  }

  isOpened() {
    return this.sidenavService.isOpened();
  }

  setOpened(opened) {
    this.sidenavService.setOpened(opened);
  }
}
