import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { LanguageService } from 'src/app/language/language.service';
import { TechnologyService } from 'src/app/services/technology.service';
import { Technology } from 'src/app/models/technology.model';
import { Level } from 'src/app/models/level.model';
import { LevelService } from 'src/app/services/level.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Question } from 'src/app/models/question.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidator } from 'src/app/tools/validator';
import { QuestionService } from 'src/app/services/question.service';
import { TypeQuestion } from 'src/app/models/typeQuestion';
import { TypeQuestionService } from 'src/app/services/typequestion.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { Proposition } from 'src/app/models/proposition.model';
import { PropositionService } from 'src/app/services/proposition.service';
import { ImagesService } from 'src/app/services/images-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-question',
  templateUrl: './view-question.component.html',
  styleUrls: ['./view-question.component.scss']
})
export class ViewQuestionnaireComponent implements OnInit {

  cnxForm: FormGroup;
  qcmForm: FormGroup;
  qcuForm: FormGroup;

  error: string;
  hasError: boolean;

  proposition: Proposition;
  question: Question;
  technologies: Technology[];
  levels: Level[];
  typeQuestions: TypeQuestion[];
  image: string;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private technologyService: TechnologyService,
              private levelService: LevelService, private typeQuestionService: TypeQuestionService,
              private questionService: QuestionService, private propositionService: PropositionService, private route: ActivatedRoute, private router: Router,
              private translate: LanguageService, private imagesService: ImagesService, private sanitizer: DomSanitizer) {

    this.technologyService.getList().subscribe(technologies => {
      this.technologies = technologies;
    });

    this.loadLevels();

    this.loadTypeQuestions();

    this.translate.addWebLanguageChangeListener(() => {
      this.loadLevels();
      this.loadTypeQuestions();
    })
  }

  ngOnInit() {
    this.question = new Question();
    this.question.id = this.route.snapshot.params.id;

    this.cnxForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: [''],
      technology: [null, [Validators.required]],
      level: [null, [Validators.required]],
      nbPoints: [1, [Validators.required, Validators.min(1)]],
      timeMinutes: [0, [Validators.required, Validators.min(0)]],
      timeSeconds: [0, [Validators.required, Validators.min(0), Validators.max(59)]],
      type: [null, [Validators.required]],
    }, { validators: CustomValidator.timeMinuteSecondCheck });

    this.qcmForm = this.formBuilder.group({
      propositions: this.formBuilder.array([]),
    });

    this.qcuForm = this.formBuilder.group({
      propositions: this.formBuilder.array([]),
      just: [null, [Validators.required]],
    });


    this.refreshQuestionnaire();
  }

  loadLevels() {
    this.levelService.getList().subscribe(levels => {
      this.levels = levels;
    });
  }

  loadTypeQuestions() {
    this.typeQuestionService.getList().subscribe(typeQuestions => {
      this.typeQuestions = typeQuestions;
    });
  }

  refreshQuestionnaire() {
    this.questionService.getById(this.question.id).subscribe(question => {
      this.question = question;
      
      // Getting the image
      this.getImage(question.imageName);
      this.cnxForm.get('name').setValue(question.question);
      this.cnxForm.get('code').setValue(question.code);
      this.cnxForm.get('technology').setValue(question.technology);
      this.cnxForm.get('level').setValue(question.level);
      this.cnxForm.get('nbPoints').setValue(question.nbPoints);
      this.cnxForm.get('timeMinutes').setValue(question.getMinutes());
      this.cnxForm.get('timeSeconds').setValue(question.getSeconds());
      this.cnxForm.get('type').setValue(this.typeQuestions.find((t) => t.id === question.type.id));

      if (this.isQCM()) {
        let i = 0;

        for (const proposition of question.propositions) {
          this.addProposition();
          const propositionControl = this.getPropositionQCM(i++);

          propositionControl.get('id').setValue(proposition.id);
          propositionControl.get('name').setValue(proposition.proposition);
          propositionControl.get('just').setValue(proposition.just);
        }
      } else if (this.isSingle()) {
        let i = 0;
        let valueTrue = null;

        for (const proposition of question.propositions) {
          this.addProposition();
          const propositionControl = this.getPropositionQCU(i++);

          propositionControl.get('id').setValue(proposition.id);
          propositionControl.get('name').setValue(proposition.proposition);

          if (proposition.just) {
            valueTrue = propositionControl;
          }
        }

        this.qcuForm.get('just').setValue(valueTrue);
      }
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
        case 404:
          this.router.navigate(['questionnaires']);
      }
    });
  }

  getPropositionsQCM(): FormArray {
    return this.qcmForm.get('propositions') as FormArray;
  }

  getPropositionsQCU(): FormArray {
    return this.qcuForm.get('propositions') as FormArray;
  }

  getPropositionQCM(i: number): FormGroup {
    return this.getPropositionsQCM().get(i.toString()) as FormGroup;
  }

  getPropositionQCU(i: number): FormGroup {
    return this.getPropositionsQCU().get(i.toString()) as FormGroup;
  }

  addProposition() {
    const idControl = this.formBuilder.control(0);
    const nameControl = this.formBuilder.control('', Validators.required);

    this.getPropositionsQCM().push(this.formBuilder.group({
      id: idControl,
      name: nameControl,
      just: [false],
    }));

    this.getPropositionsQCU().push(this.formBuilder.group({
      id: idControl,
      name: nameControl,
    }));


  }

  deleteProposition(i: number) {
    this.getPropositionsQCM().removeAt(i);
    this.getPropositionsQCU().removeAt(i);
  }

  isSameTechnology(o1: Technology, o2: Technology) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }

  isSameLevel(o1: Level, o2: Level) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }

  isSameTypeQuestion(o1: TypeQuestion, o2: TypeQuestion) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }

  isValidQuestionRealTime() {
    if (this.cnxForm.invalid) {
      return false;
    }

    if (this.isQCM()) {

      // Pour validite checkbox
      for (const propositionGroup of this.getPropositionsQCM().controls) {
        propositionGroup.updateValueAndValidity();
      }

      if (this.qcmForm.invalid || this.getPropositionsQCM().length < 2) {
        return false;
      }

      let oneJust = false;
      let oneFalse = false;

      for (const propositionGroup of this.getPropositionsQCM().controls) {
        if (propositionGroup.get('just').value) {
          oneJust = true;
        } else {
          oneFalse = true;
        }

        if (oneJust && oneFalse) {
          break;
        }
      }

      if (!oneJust || !oneFalse) {
        return false;
      }
    } else if (this.isSingle()) {
      if (this.qcuForm.invalid || this.getPropositionsQCU().length < 2) {
        return false;
      }

      if (this.getPropositionsQCU().length < 2) {
        return false;
      }
    }

    return true;
  }

  getTypeSelected(): TypeQuestion {
    return this.cnxForm.get('type').value;
  }

  isQCM() {
    const selected = this.getTypeSelected();
    return selected != null && selected.equals(TypeQuestionService.QCM);
  }

  isSingle() {
    const selected = this.getTypeSelected();
    return selected != null && selected.equals(TypeQuestionService.SINGLE);
  }

  /* REPORT DES QUESTION OU LA REPONSE EST UNE REDACTION
  isCode() {
    const selected = this.getTypeSelected();
    return selected != null && selected.equals(TypeQuestionService.CODE);
  }*/

  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }
 // Appel à la methode submit
  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('update.inProgress')}
    });

    this.question.question = this.cnxForm.get('name').value;
    this.question.technology = this.cnxForm.get('technology').value;
    this.question.level = this.cnxForm.get('level').value;
    this.question.code = this.cnxForm.get('code').value;
    this.question.nbPoints = this.cnxForm.get('nbPoints').value;
    this.question.setMinutes(this.cnxForm.get('timeMinutes').value);
    this.question.setSeconds(this.cnxForm.get('timeSeconds').value);
    this.question.type = this.cnxForm.get('type').value;
    if (this.isQCM()) {
      for (const propositionGroup of this.getPropositionsQCM().controls) {
        const index = this.question.propositions.findIndex(obj => obj.id === propositionGroup.get('id').value);
        if (index !== -1) {
          this.question.propositions[index].proposition = propositionGroup.get('name').value;
          this.question.propositions[index].just = propositionGroup.get('just').value ? true : false;
        }
      }     
    }
    if (this.isSingle()) {
      const selected = this.qcuForm.get('just').value;

      for (const propositionGroup of this.getPropositionsQCU().controls) {
        const index = this.question.propositions.findIndex(obj => obj.id === propositionGroup.get('id').value);
        if (index !== -1) {
          this.question.propositions[index].proposition = propositionGroup.get('name').value;
          this.question.propositions[index].just = selected === propositionGroup;
        }
      }
    }
 

    // appel au service update
    this.questionService.update(this.question).subscribe(() => {
      dialog.close();
      this.hasError = false;

      this.dialog.open(ValidErrorDialogComponent, {
        data: {title: this.translate.getWord('update.valid'), state: 1}
      })
      // Retour à la liste des tests
      this.router.navigate(['questions']);
    }, 
    (error: HttpErrorResponse) => {
      dialog.close();
      this.hasError = true;
    });
  }

// API DELETE

onDelete() {
  this.dialog.open(ConfirmationDialogComponent, {
    disableClose: true,
    data: {title: this.translate.getWord('question.deleteInProgress')}
  })
  .afterClosed().subscribe(result => {
    if (result) {
      const loadDialog = this.dialog.open(LoadDialogComponent, {
        disableClose: true,
        data: {title: this.translate.getWord('delete.inProgress')}
      });

      this.questionService.delete(this.question).subscribe(() => {
        loadDialog.close();
        this.hasError = false;

        this.dialog.open(ValidErrorDialogComponent, {
          data: {title: this.translate.getWord('delete.valid'), state: 1}
        })
        .afterClosed().subscribe(() => this.router.navigate(['questions']));
      },
      (error: HttpErrorResponse) => {
        loadDialog.close();
        this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
        this.hasError = true;
      });
    }
  });
}
  user(user: any) {
    throw new Error("Method not implemented.");
  }

  getImage(imageName: string) {
    if(imageName && imageName!= '') {
      this.imagesService.getImage(imageName).subscribe((res: string) => {
      this.image = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + res)  as string;
      });
    }
  }

}
