import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Proposition } from '../models/proposition.model';
import { Level } from '../models/level.model';
import { Technology } from '../models/technology.model';
import { TypeQuestion } from '../models/typeQuestion';

@Injectable({
  providedIn: 'root'
})
export class PropositionService {

  private static URL = '/PolymatheeWeb/propositions';

  constructor(private http: HttpClient) {}

  
  update(proposition: Proposition) {
    return this.http.put<Proposition>(PropositionService.URL + '/' + proposition.id, proposition)
      .pipe(map(q => {
        return new Proposition(q);
    }));
  }


  getById(id: number): Observable<Proposition> {
    return this.http.get<Proposition>(PropositionService.URL + '/' + id)
      .pipe(map(q => {
        return new Proposition(q);
      }));
  }
  
}
