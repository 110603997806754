import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PopupToDeleteTestComponent } from './popup-to-delete-test.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {

  idTest;
  context;

  constructor(private dialog: MatDialog) {}

  openPopup() {
    this.dialog.open(PopupToDeleteTestComponent);
  }

  setIdTest(idTest){
    this.idTest = idTest ;
  }

  getIdTest(){
    return this.idTest ;
  }

  setContext(context){
    this.context = context ;
  }

  getContext(){
    return this.context;
  }
}