import { Component } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-wait-candidate',
  templateUrl: './wait-candidate.component.html',
  styleUrls: ['./wait-candidate.component.scss']
})
export class WaitCandidateComponent {

  usersMailNoValid: User[];
  usersWaiting: User[];
  selection = new SelectionModel<User>(true, []);

  constructor(private userService: UserService, private responsiveService: ResponsiveService) {
    this.refreshUsers();
  }

  getUserMailNoValidDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? [ 'name', 'firstname', 'action']
      : [ 'name', 'firstname', 'mail', 'dateCreation', 'action'];
  }

  getUsersWaitingDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ['select', 'name', 'firstname', 'action']
      : ['select', 'name', 'firstname', 'mail', 'dateCreation', 'action'];
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return this.selection.selected.length === this.usersWaiting.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() : this.usersWaiting.forEach(row => this.selection.select(row));
  }

  validUsers() {
    console.log("VALIDATION UTILISATEUR AVANT TRAITEMENT DE LA DEMANDE D'INSCRIPTION");
    for (const user of this.selection.selected) {
      console.log("Dans la boucle avant appel WS");
      this.userService.validUser(user).subscribe(_=> {
      });
    }

    this.refreshUsers();
  }

  refuseUsers() {
    for (const user of this.selection.selected) {
      this.userService.refuseUser(user).subscribe(_=> {
      });
    }

    this.refreshUsers();
  }

  refreshUsers() {
    this.userService.getCandidatesList(null, false).subscribe(users => {
      this.usersMailNoValid = users;
    });

    this.userService.getCandidatesList(StatusService.WAITING, true).subscribe(users => {
        this.usersWaiting = users;
    });
  }
}
