import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuComponent } from './menu.component';
import { NavItemComponent } from './nav-item/nav-item.component';

@NgModule({
    declarations: [
        MenuComponent,
        NavItemComponent
    ],
    exports: [
        MenuComponent
    ],
    imports: [
        MaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule
    ],
    providers: []
})
export class MenuModule { }
