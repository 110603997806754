import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable()
export class LanguageHTTPInterceptor implements HttpInterceptor {

    constructor(private languageService: LanguageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.languageService.getWebLanguage() != null) {
            const modifiedReq = req.clone({
                params: req.params.set('lang', this.languageService.getWebLanguage().format),
            });

            return next.handle(modifiedReq);
        } else {
            return next.handle(req);
        }
    }
}
