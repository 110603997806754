import { Component, Input } from '@angular/core';
import { Language } from '../models/language.model';
import { LanguageService } from './language.service';
import { ResponsiveService } from '../tools/responsive.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {

  @Input() compact: boolean;
  languages: Language[];

  constructor(private languageService: LanguageService, private responsiveService: ResponsiveService) {}

  getLanguage(): Language {
    return this.languageService.getWebLanguage();
  }

  getLanguages(): Language[] {
    return this.languageService.getLanguages();
  }

  setLanguage(language: Language) {
    this.languageService.setWebLanguage(language);
  }

  canShowName(): boolean {
    return !this.compact || !this.responsiveService.isOnPortableScreen();
  }
}
