import { User } from '../user.model';

export class LevelSchema {
    id?: number;
    name?: string;
    dateCreation?: Date;
    userCreation?: User;

    constructor(level: LevelSchema = {}) {
        this.id = level.id;
        this.name = level.name;

        if (level.dateCreation) {
            this.dateCreation = new Date(level.dateCreation);
        }

        if (level.dateCreation) {
            this.userCreation = new User(level.userCreation);
        }
    }
}
