import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Exam } from '../models/exam.model';
import { Answer } from '../models/answer.model';
import { Question } from '../models/question.model';
import { Proposition } from '../models/proposition.model';

@Injectable({
  providedIn: 'root'
})
export class CandidateAnswersService {

  private static URL = '/PolymatheeWeb/candidateAnswers';

  constructor(private http: HttpClient) {}

  getById(id: number): Observable<Answer> {
    return this.http.get<Answer>(CandidateAnswersService.URL + '/' + id)
      .pipe(map(q => {
        return new Answer(q);
      }));
  }

  getList(exam: Exam, question: Question, proposition: Proposition): Observable<Answer[]> {
    let url = CandidateAnswersService.URL + '?';

    if (exam) {
      url += '&exam=' + exam.id;
    }

    if (question) {
      url += '&question=' + question.id;
    }

    if (proposition) {
      url += '&proposition=' + proposition.id;
    }

    return this.http.get<Answer[]>(url)
      .pipe(map(answers => {
        answers.forEach((q, i, t) => t[i] = new Answer(q));
        return answers;
      }));
  }


}
