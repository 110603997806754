import { User } from '../user.model';
import { Test } from '../test.model';
import { StatusExam } from '../status-exam.model';
import { Answer } from '../answer.model';
import { MarkTechnology } from '../markTechnology.model';

export class ExamSchema {
    id?: number;
    candidate?: User;
    recruiter?: User;
    test?: Test;
    creationDate?: Date;
    dateStartPassage?: Date;
    dateEndPassage?: Date;
    mark?: number;
    statutExam?: StatusExam;
    answers?: Answer[];
    markByLanguage?: MarkTechnology[];

    constructor(exam: ExamSchema = {}) {
        this.id = exam.id;
        if (exam.candidate) {
            this.candidate = new User(exam.candidate);
        }

        if (exam.recruiter) {
            this.recruiter = new User(exam.recruiter);
        }

        if (exam.test) {
            this.test = new Test(exam.test);
        }

        if (exam.creationDate) {
            this.creationDate = new Date(exam.creationDate);
        }

        if (exam.dateStartPassage) {
            this.dateStartPassage = new Date(exam.dateStartPassage);
        }

        if (exam.dateEndPassage) {
            this.dateEndPassage = new Date(exam.dateEndPassage);
        }

        this.mark = exam.mark;

        if (exam.statutExam) {
            this.statutExam = new StatusExam(exam.statutExam);
        }

        if (exam.answers) {
            exam.answers.forEach((v, i, t) => t[i] = new Answer(v));
            this.answers = exam.answers;
        } else {
            this.answers = [];
        }

        if (exam.markByLanguage) {
            exam.markByLanguage.forEach((v, i, t) => t[i] = new MarkTechnology(v));
            this.markByLanguage = exam.markByLanguage;
        } else {
            this.markByLanguage = [];
        }
    }
}
