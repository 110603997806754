import { Component, OnInit } from '@angular/core';
import { Exam } from 'src/app/models/exam.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { StatusExamService } from 'src/app/services/status-exam.service';
import { StatusExam } from 'src/app/models/status-exam.model';
import { ExamService } from '../services/exam.service';
import { ActivatedRoute } from '@angular/router';
import { Question } from '../models/question.model';
@Component({
  selector: 'app-exam-details',
  templateUrl: './exam-details.component.html',
  styleUrls: ['./exam-details.component.scss']
})
export class ExamDetailsComponent implements OnInit {
  private exam: Exam;
  grouped_questions: { [technology: string]: { questions: Question[], mark: number } } = {} = {};


  constructor(private route: ActivatedRoute, private signinService: SigninService, private examService : ExamService) {
  }

  ngOnInit(): void {
    this.examService.getById(this.route.snapshot.params['id']).subscribe(
      result => {
        this.exam = result;
        for (const question of this.exam.test.questions) {
          const technologyName = question.technology.name;
          const questionId = question.id;
      
          if (!this.grouped_questions[technologyName]) {
            this.grouped_questions[technologyName] = { questions: [], mark: 0 };
          }
      
          this.grouped_questions[technologyName].questions.push(question);
      
          for (const proposition of question.propositions) {
              proposition["isCandidateAnswer"] = this.exam.answers.some(answer => answer.question.id === questionId && answer.propositions.some(answerProp => answerProp.id === proposition.id));
          }
        }
        for (const markData of this.exam.markByLanguage) {
          const technologyName = markData.technology.name;
      
          if (this.grouped_questions[technologyName]) {
            this.grouped_questions[technologyName].mark = markData.mark;
          }
        }
        for (const answer of this.exam.answers) {
          const questionId = answer.question.id;
      
          for (const technology in this.grouped_questions) {
              const questions = this.grouped_questions[technology].questions;
              const foundQuestion = questions.find(question => question.id === questionId);
      
              if (foundQuestion) {
                  foundQuestion["mark"] = answer.mark;
                  break; // No need to continue searching in this technology
              }
          }
        }
        for (const technology in this.grouped_questions) {
          const questions = this.grouped_questions[technology].questions;
          
          for (const question of questions) {
              if (question['mark'] === undefined) {
                  question['mark'] = 0;
              }
          }
        }
      }
    )
  }

  /* l'examen n'a pas encore commencer*/
  isNotStart(exam: Exam) {
    return this.checkExamStatus(exam, StatusExamService.AFFECTED);
  }
 /* l'examen en cours*/
  isInProgress(exam: Exam) {
    return this.checkExamStatus(exam, StatusExamService.IN_PROGRESS);
  }
 /* l'examen est finit*/
  isFinish(exam: Exam) {
    return this.checkExamStatus(exam, StatusExamService.FINISHED);
  }
/* verifier le statut de l'examen*/
  checkExamStatus(exam: Exam, statusExam: StatusExam) {
    return exam != null && exam.statutExam.equals(statusExam);
  }

  isRecruiter(){
    return this.signinService.getUser().profil.name === 'RECRUTEUR';
  }
}
