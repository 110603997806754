import { UserSchema } from './interface/user.model';
import { Technology } from './technology.model';

export class User extends UserSchema {

    getFullName(): string {
        return this.name && this.firstname ? `${this.name} ${this.firstname}` : null;
    }

    getPhoneNumberFrenchFormat(): string {
        return this.phoneNumber != null && this.phoneNumber.length > 0 ? '0' + this.phoneNumber.substr(2) : null;
    }

    setPhoneNumberFrenchFormat(phoneNumber: string) {
        this.phoneNumber = phoneNumber.length > 0 ? '33' + phoneNumber.substr(1) : '';
    }

    getLastPassage(technologies: Technology[]) {
        return null;
    }

/*    getLastPassage(technologies: Technology[]) {
        const passages = this.getPassagesByTechnologies(technologies);

        if (passages && passages.length > 0) {
            return passages[0];
        }

        return null;
    }*/

    getPassagesByTechnologies(technologies: Technology[]) {
        return [];
    }

    // getPassagesByTechnologies(technologies: Technology[]) {
    //     if (this.passages && this.passages.length > 0) {
    //         if (technologies && technologies.length > 0) {
    //             const passages = [];
    //
    //             this.passages.forEach(passage => {
    //                 if (technologies.findIndex(t => passage.test.technology.equals(t)) >= 0) {
    //                     passages.push(passage);
    //                 }
    //             });
    //
    //             return passages;
    //         }
    //
    //         return this.passages;
    //     }
    //
    //     return [];
    // }

    // getStringLanguages(technologies: Technology[]): string {
    //     return '';
    // }

    getStringLanguages(technologies: Technology[]): string {

        return this.getPassagesByTechnologies(technologies).map(p => p.test.technology.name)
            .filter((v, i, t) => t.indexOf(v) === i).join(', ');
    }

    getAverageMarkByTechnologies(technologies: Technology[]): number {
        return 0;
    }

/*    getAverageMarkByTechnologies(technologies: Technology[]): number {
        const passages = this.getPassagesByTechnologies(technologies);

        if (passages.length > 0) {
            let mark = 0;

            passages.forEach(passage => mark += passage.mark);

            return mark / passages.length;
        }

        return 0;
    }*/

    equals(user: User): boolean {
        return this.id === user.id;
    }
}
