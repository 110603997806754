import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Exam } from 'src/app/models/exam.model';
import { ExamService } from 'src/app/services/exam.service';
import { ToolbarService } from 'src/app/toolbar/toolbar.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { TypeQuestionService } from 'src/app/services/typequestion.service';
import { Question } from 'src/app/models/question.model';
import { StatusExamService } from 'src/app/services/status-exam.service';
import { TypeQuestion } from 'src/app/models/typeQuestion';
import { StatusExam } from 'src/app/models/status-exam.model';
import { MatHorizontalStepper, MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { Proposition } from 'src/app/models/proposition.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/language/language.service';
import { Answer } from 'src/app/models/answer.model';
import { CanComponentDeactivate } from 'src/app/services/guards/exam-guard.service';
import { InfoDialogComponent } from 'src/app/dialog/info-dialog/info-dialog.component';
import { TimePipe } from 'src/app/tools/time.pipe';
import { ImagesService } from 'src/app/services/images-service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pass-exam',
  templateUrl: './pass-exam.component.html',
  styleUrls: ['./pass-exam.component.scss']
})
export class PassExamComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  exam: Exam;
  examCanceled: boolean = false ;
  answer = {};
  passedTime: number = 0 ;
  seconds: any;
  id: number;
  
  intervalId: number = 0;
  intervalTime: any;
  message: string = '';

  imagesMap = new Map<number, string>();


  @ViewChild(MatHorizontalStepper, {static: false}) stepper: MatHorizontalStepper;
  constructor(
    private examService: ExamService,
    private toolbarService: ToolbarService,
    public dialog: MatDialog,
    signinService: SigninService,
    route: ActivatedRoute,
    private router: Router,
    private translate: LanguageService,
    private timePipe: TimePipe,
    private imagesService: ImagesService,
    private sanitizer: DomSanitizer)
  {

    signinService.refreshUser().subscribe(() => {
      const user = signinService.getUser();
      const id = parseInt(route.snapshot.params.id, 10);

      this.exam = user.passages.find(v => v.id === id);
      this.getImages(this.exam);

      if (this.exam == null) {
        router.navigateByUrl('/tests');
      }

      if (this.isInProgress()) {
        examService.getById(this.exam.id).subscribe(exam => (this.setExamStart(exam)));
      
      
      } else if (this.isFinish()) {
        this.toolbarService.setTitle('Examen fini');
      }
    });
  }

  public canDeactivate(): boolean {
    if (this.isInProgress()) {
      this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {title: this.translate.getWord('examen.quit')}
      })
      .afterClosed().subscribe(result => {
        if (result) {
          clearInterval(this.intervalId);
          this.examService.finish(this.exam).subscribe(exam => this.exam = exam);
          this.toolbarService.setTitle('Examen fini');
          return true;
        }
        return false
      });
    }
    else
      return true; 
   }

  ngOnInit(): void {
    this.translate.addWebLanguageChangeListener(() => {
      if (this.isInProgress()) {
        // Pour causer l'update de langue dynamique lors d'un changement d'etapes
        const saveV = this.stepper.selectedIndex;
        this.stepper.selectedIndex = 0;
        this.stepper.selectedIndex = saveV;
      }
    });
  }

  detectFraud(): void {
    if(!this.examCanceled){
      window.addEventListener('visibilitychange', (event) => {
        if ((document.visibilityState == 'hidden')) {
          this.stopExamFraudDetected(event);
        }
      })

      window.addEventListener('mousemove', (event) => {
        if (this.passedTime >= 30 && !this.examCanceled && !this.isFinish()){
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
              title: this.translate.getWord('examen.CanceledExamTitle'),
              description: this.translate.getWord('examen.CanceledExamDescription')
            }
          });
          setTimeout(() => { dialogRef.close() }, 7000);
          this.stopExamFraudDetected(event);
        } else {
          this.passedTime = 0 ;
        }
      })

      window.addEventListener('keypress', (event) => {
        if (this.passedTime >= 30 && !this.examCanceled && !this.isFinish()) {
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
              title: this.translate.getWord('examen.CanceledExamTitle'),
              description: this.translate.getWord('examen.CanceledExamDescription')
            }
          });
          setTimeout(() => { dialogRef.close() }, 7000);
          this.stopExamFraudDetected(event);
        } else {
          this.passedTime = 0 ;
        }
      })
    }
  }

  stopExamFraudDetected(event: any){
    this.examService.setFraud(true); 
    event.preventDefault();
    event.stopPropagation();
    clearInterval(this.intervalTime);
    this.exam.mark = 0 ;
    this.exam.statutExam = new StatusExam(StatusExamService.FRAUDED);
    if (this.examCanceled == false)
      this.examService.fraud(this.exam).subscribe(exam => this.exam = exam);
    this.examCanceled = true ;
  }

  ngOnDestroy(): void {
    this.toolbarService.setTitle('');
    this.examCanceled = false ;
    this.examService.setFraud(false);
  }

  setExamStart(exam: Exam) {
  // this.seconds=  exam.test.getTimeMinutes() + exam.test.getTimeSeconds();
    this.seconds=this.exam.test.time;
    //this.seconds=this.exam.test.getTimeSeconds();

    this.countDown();
    this.exam = exam;

    for (const question of exam.test.questions) {
      if (this.isQCM(question)) {
        this.answer[question.id] = {};
        for (const proposition of question.propositions) {
          this.answer[question.id][proposition.id] = false;
        }
      } else if (this.isSingle(question)) {
        this.answer[question.id] = null;
      } else {
        this.answer[question.id] = '';
      }
    }

    for (const answer of exam.answers) {
      if (this.isQCM(answer.question)) {
        for (const proposition of answer.propositions) {
          this.answer[answer.question.id][proposition.id] = true;
        }
      } else if (this.isSingle(answer.question)) {
        if (answer.propositions.length > 0) {
          this.answer[answer.question.id] = answer.propositions[0].id;
        }
      } else {
        this.answer[answer.question.id] = answer.code;
      }
    }

    this.setToolbarQuestion(1);

    setTimeout(() => this.goOnStep(0), 0);
 
  }

  goOnStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  isFirstStep() {
    return this.stepper == null || this.stepper.selectedIndex === 0;
  }

  isSecondLastStep() {
    return this.stepper != null && this.stepper.selectedIndex === this.stepper.steps.length - 2;
  }

  getStepMaxIndex() {
    return this.stepper != null ? this.stepper.steps.length - 1 : 0;
  }

  onChangeStep(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex !== this.getStepMaxIndex()) {
      const answer = new Answer();

      answer.question = this.exam.test.questions[event.previouslySelectedIndex];

      /* REPORT DES QUESTION OU LA REPONSE EST UNE REDACTION
      if (this.isCode(answer.question)) {
        answer.code = this.getCode(answer.question);
      } else {*/
        for (const proposition of answer.question.propositions) {
          if (this.isCheckedProposition(answer.question, proposition)) {
            answer.propositions.push(proposition);
          }
        }
      //}

      this.examService.saveAnswer(this.exam, answer).subscribe();
    }

    if (event.selectedIndex === this.getStepMaxIndex()) {
      this.toolbarService.setTitle(this.translate.getWord('word.recapitulatif'));
    } else {
      this.setToolbarQuestion(event.selectedIndex + 1);
    }
  }

  setToolbarQuestion(i: number) {
    this.toolbarService.setTitle('Question ' + i);
  }

  isNotStart() {
    return this.checkExamStatus(StatusExamService.AFFECTED);
  }

  isInProgress() {
    return this.checkExamStatus(StatusExamService.IN_PROGRESS);
  }

  isFinish() {
    return this.checkExamStatus(StatusExamService.FINISHED);
  }

  checkExamStatus(statusExam: StatusExam) {
    return this.exam != null && this.exam.statutExam.equals(statusExam);
  }

  isQCM(question: Question) {
    return this.checkTypeQuestion(question, TypeQuestionService.QCM);
  }

  isSingle(question: Question) {
    return this.checkTypeQuestion(question, TypeQuestionService.SINGLE);
  }

  /* REPORT DES QUESTION OU LA REPONSE EST UNE REDACTION
  isCode(question: Question) {
    return this.checkTypeQuestion(question, TypeQuestionService.CODE);
  }*/

  checkTypeQuestion(question: Question, typeQuestion: TypeQuestion) {
    return question != null && question.type.equals(typeQuestion);
  }

  isCheckedProposition(question: Question, proposition: Proposition) {
    if (this.isQCM(question)) {
      return this.answer[question.id][proposition.id];
    } else if (this.isSingle(question)) {
      return this.answer[question.id] === proposition.id;
    }

    return false;
  }

  getCode(question: Question) {
    /* REPORT DES QUESTION OU LA REPONSE EST UNE REDACTION
    if (this.isCode(question) && this.answer[question.id]) {
      return this.answer[question.id];
    }*/

    return '';
  }

  getNbLines(textarea: HTMLTextAreaElement) {
    const nbLines = textarea.value.split('\n').length;

    return Array(nbLines).fill(0).map((x, i) => i + 1);
  }

  onStart() {
    this.examService.start(this.exam).subscribe(exam => this.setExamStart(exam));
    this.detectFraud();
    this.intervalTime = setInterval(() => this.passedTime++, 1000);
  }

  onTab(event: KeyboardEvent) {
    event.preventDefault();

    const textarea = event.target as HTMLTextAreaElement;

    const selectStart = textarea.selectionStart;
    const selectEnd = textarea.selectionEnd;

    const startText = textarea.value.substr(0, selectStart);

    const nbLinesSelected = textarea.value.substr(selectStart, selectEnd - selectStart).split('\n').length;

    if (nbLinesSelected === 1) {
      this.setTextareaValueCursor(textarea, startText + '\t' + textarea.value.substr(selectEnd), selectStart + 1);
    } else {
      const linesStart = startText.split('\n').length - 1;
      const lines = textarea.value.split('\n');

      for (let i = linesStart; i < linesStart + nbLinesSelected; i++) {
        lines[i] = '\t' + lines[i];
      }

      this.setTextareaValueCursor(textarea, lines.join('\n'), selectStart + 1, selectEnd + nbLinesSelected);
    }
  }

  onShiftTab(event: KeyboardEvent) {
    event.preventDefault();

    const textarea = event.target as HTMLTextAreaElement;

    const selectStart = textarea.selectionStart;
    const selectEnd = textarea.selectionEnd;

    const startText = textarea.value.substr(0, selectStart);

    const nbLinesSelected = textarea.value.substr(selectStart, selectEnd - selectStart).split('\n').length;

    if (nbLinesSelected > 1) {
      const linesStart = startText.split('\n').length - 1;
      const lines = textarea.value.split('\n');
      let nbTab = 0;

      for (let i = linesStart; i < linesStart + nbLinesSelected; i++) {
        if (lines[i].length !== 0 && lines[i][0] === '\t') {
          lines[i] = lines[i].substr(1);
          nbTab++;
        }
      }

      this.setTextareaValueCursor(textarea, lines.join('\n'),
        lines[linesStart].length !== 0 && lines[linesStart][0] === '\t' ? selectStart - 1 : selectStart, selectEnd - nbTab);
    }
  }

  onEnter(event: KeyboardEvent) {
    event.preventDefault();

    const textarea = event.target as HTMLTextAreaElement;

    const selectStart = textarea.selectionStart;
    const selectEnd = textarea.selectionEnd;

    const startText = textarea.value.substr(0, selectStart);

    const linesStart = startText.split('\n').length - 1;
    const lines = textarea.value.split('\n');

    let nbTab = 0;

    for (const char of lines[linesStart]) {
      if (char !== '\t') {
        break;
      }
      nbTab++;
    }

    this.setTextareaValueCursor(textarea, startText + '\n' + '\t'.repeat(nbTab) + textarea.value.substr(selectEnd),
      selectStart + 1 + nbTab);
  }

  onSpecialDoubleChar(event: KeyboardEvent, startSep: string, endSep: string) {
    const textarea = event.target as HTMLTextAreaElement;

    let selectStart = textarea.selectionStart;
    let selectEnd = textarea.selectionEnd;
    const startText = textarea.value.substr(0, selectStart);
    const endText = textarea.value.substr(selectEnd);

    // Empeche la duplication du seperateur fermant
    if (event.key === endSep && selectStart === selectEnd && endText.length > 0 && endText[0] === endSep) {
      event.preventDefault();

      textarea.selectionStart = selectStart + 1;
      textarea.selectionEnd = textarea.selectionStart;
      textarea.blur();
      textarea.focus();

    // Ajout du seperateur fermant commun si seperateur ouvrant en insertion (ajouter a cote de lui)
    } else if (event.key === startSep) {
      event.preventDefault();

      const middleText = textarea.value.substr(selectStart, selectEnd - selectStart);

      this.setTextareaValueCursor(textarea, startText + startSep + middleText + endSep + endText, selectStart + 1, selectEnd + 1);

    // Suppression seperateur fermant commun si seperateur ouvrant en suppression (doivent etre cote a cote)
    } else if (event.key === 'Backspace'
              && selectStart === selectEnd && startText.length > 0 && endText.length > 0
              && startText[startText.length - 1] === startSep && endText[0] === endSep) {
      event.preventDefault();

      this.setTextareaValueCursor(textarea, startText.substr(0, startText.length - 1) + endText.substr(1), selectStart - 1);

    } else if (event.key === 'Enter' // Ajout d'un tab apres saut de ligne entre deux seperateurs communs
              && selectStart === selectEnd && startText.trim().length > 0 && endText.length > 0
              && startText.trim()[startText.trim().length - 1] === startSep && endText[0] === endSep) {
      this.onEnter(event);

      selectStart = textarea.selectionStart;
      selectEnd = textarea.selectionEnd;

      const linesStart = startText.split('\n').length - 1;
      const lines = textarea.value.split('\n');

      lines[linesStart] += '\t';

      this.setTextareaValueCursor(textarea, lines.join('\n'), startText.length + 1);
    }
  }

  setTextareaValueCursor(textarea: HTMLTextAreaElement, value: string, selectStart: number, selectEnd?: number) {
    textarea.value = value;
    textarea.selectionStart = selectStart;

    if (selectEnd) {
      textarea.selectionEnd = selectEnd;
    } else {
      textarea.selectionEnd = selectStart;
    }

    textarea.blur();
    textarea.focus();
  }

  onSubmit() {
    this.examCanceled = false ;
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {title: 'Valider les réponses à l\'examen ?'}
    })
    .afterClosed().subscribe(result => {
      if (result) {
        clearInterval(this.intervalId);
        this.examService.finish(this.exam).subscribe(exam => this.exam = exam);
        this.toolbarService.setTitle('Examen fini');
      }
    });
  }

  /////// COUNT DOWN ///////
 
  private countDown(): void {
   
    this.intervalId = window.setInterval(() => {
      this.seconds-=1;
      if (this.seconds === 0) {
        clearInterval(this.intervalId);
        this.examService.finish(this.exam).subscribe(exam => this.exam = exam);
      } else {
        if (this.seconds < 0) { this.seconds = 10; } // reset
        this.message = `Le temps qui vous reste est ${this.timePipe.transform(this.seconds)}`;
      }
    }, 1000);
  }

  getImages(exam: Exam) {
    for(let question of exam.test.questions) {
      if(question.imageName && question.imageName!= ''){
          this.imagesService.getImage(question.imageName).subscribe((res: string) => {
          let image = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + res)  as string;
          this.imagesMap.set(question.id, image);
        });
      }
    }
  }

  

}
