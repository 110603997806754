import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { map } from 'rxjs/operators';
import { Menu } from 'src/app/models/menu.model';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private http: HttpClient, private router: Router, private userService: UserService) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();

    const userStr = localStorage.getItem('user');

    if (userStr) {
      this.userSubject.next(new User(JSON.parse(userStr)));
    }
  }

  getUser(): User {
    return this.userSubject.value;
  }

  isAuth() {
    return this.userSubject.value != null;
  }

  signin(mail: string, password: string) {
    return this.http.post<User>('/PolymatheeWeb/login', {mail, password}).pipe(map(user => {
      if (user) {
        user = new User(user);
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
      }
      return user;
    }));
  }

  refreshUser() {
    if (this.isAuth()) {

      return this.userService.getById(this.getUser().id).pipe(map(user => {
        if (user) {
          user.profil.menus = this.getUser().profil.menus;
          user = new User(user);
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
        }

        return user;
      }));
    }
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
  }

  logoutWithServerNotified() {
    this.logout();
    this.http.post('/PolymatheeWeb/logout', {}).subscribe();
  }

  reloadMenu() {
    if (this.isAuth()) {
      this.http.get<Menu[]>('/PolymatheeWeb/menu').subscribe(
        menus => {
          menus.forEach((m, i, tab) => tab[i] = new Menu(m));
          this.getUser().profil.menus = menus;
        },
        (error: HttpErrorResponse) => {
          this.processHTTPError(error);
        }
      );
    }
  }

  processHTTPError(error: HttpErrorResponse) {
  }
}
