import { SchoolLevelSchema } from './interface/schoolLevel.model';

export class SchoolLevel extends SchoolLevelSchema {

    equals(schoolLevel: SchoolLevel): boolean {
        return this.id === schoolLevel.id;
    }

    getName(): string {
        return this.name  ? `${this.name} ` : null;
    }
}
