import { TestSchema } from './interface/test.model';

export class Test extends TestSchema {

    getTimeMinutes() {
        return Math.floor(this.time / 60);
    }

    getTimeSeconds() {
      /*  const hours = Math.floor(this.time / 3600);
        const minutes = Math.floor((this.time - (hours * 3600)) / 60);
        const seconds = (this.time - (hours * 3600) - (minutes * 60));
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;*/

     return this.time % 60;
    }

    getTimeHour(){
        return Math.floor(this.time/3600);
    }
  
    getTechnologies() {
        const technologies = [];

        for (const question of this.questions) {
            const technologieNb = technologies.find(v => v.technology.equals(question.technology));
            if (technologieNb) {
                technologieNb.nb++;
            } else {
                technologies.push({technology: question.technology, nb: 1});
            }
        }

        return technologies;
    }

    equals(test: Test): boolean {
        return this.id === test.id;
    }
}
