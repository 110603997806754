import { User } from '../user.model';

export class TechnologySchema {
    id?: number;
    name?: string;
    dateCreation?: Date;
    userCreation?: User;

    constructor(technology: TechnologySchema = {}) {
        this.id = technology.id;
        this.name = technology.name;

        if (technology.dateCreation) {
            this.dateCreation = new Date(technology.dateCreation);
        }

        if (technology.userCreation) {
            this.userCreation = new User(technology.userCreation);
        }
    }
}
