import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";

import { ResponsiveService } from "src/app/tools/responsive.service";
import { MatPaginator, MatTableDataSource, MatSort, MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import { TestService } from "src/app/services/test.service";
import { Test } from "src/app/models/test.model";
import { Level } from "src/app/models/level.model";
import { LevelService } from "src/app/services/level.service";
import { ConfirmationDialogComponent } from "src/app/dialog/confirmation-dialog/confirmation-dialog.component";
import { LoadDialogComponent } from "src/app/dialog/load-dialog/load-dialog.component";
import { ValidErrorDialogComponent } from "src/app/dialog/valid-error-dialog/valid-error-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { LanguageService } from "src/app/language/language.service";
import { PopupService } from './popup-to-delete-test/popup.service';

@Component({
  selector: "app-list-test",
  templateUrl: "./list-test.component.html",
  styleUrls: ["./list-test.component.scss"],
})
export class ListTestsComponent implements OnInit, AfterViewInit {
  public defaultLevel = new Level();

  private testsRequest: Subscription;
  private tests: Test[];
  private test: Test;

  dataSource: MatTableDataSource<Test>;

  // Filtres
  levels: Level[];
  level: Level;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dialog: any;
  translate: any;
  userService: any;
  hasError: boolean;
  router: any;
  error: any;
  constructor(
    private levelService: LevelService,
    private testService: TestService,
    private responsiveService: ResponsiveService,
    private languageService: LanguageService,
    private popupService: PopupService
  ) {
    this.tests = [];
    this.dataSource = new MatTableDataSource(this.tests);

    this.level = this.defaultLevel;
    this.loadLevels();

    this.refreshTestsList();
    this.languageService.addWebLanguageChangeListener(() => {
      this.loadLevels();
      this.refreshTestsList();
    });
  }

  /* Le plus simple pour faire le tri est d'utiliser cette méthode */
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    /* this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (test: Test, filter: string) => {
      filter = filter.trim().toLowerCase();

      const name = test.name.toLowerCase();

      if (name.search(filter) >= 0) {
        return true;
      }

      const dateCreation=test.dateCreation.getDate();
      if (dateCreation.toString().startsWith(filter)) {
        return true;
      }

      return false;
    };

    const defaultSort = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (test: Test, sortHeaderId: string): string | number => {
      let sortAttribut = defaultSort(test, sortHeaderId);

      switch (sortHeaderId) {
        case 'level': sortAttribut = test.level.name; break;
        case 'user': sortAttribut = test.userCreated.name; break;
        case 'status': sortAttribut = test.status.name; break;
      }

      return sortAttribut;
    };*/
  }

  getTestsDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ["name", "time", "action"]
      : ["name", "time", "dateCreation", "level", "user", "action"];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  refreshTestsList() {
    if (this.testsRequest) {
      this.testsRequest.unsubscribe();
    }

    this.testsRequest = this.testService
      .getList(this.level !== this.defaultLevel ? this.level : null)
      .subscribe((tests) => {
        this.tests.splice(0, this.tests.length);
        this.tests.push(...tests);
        this.dataSource.filter = "";
      });
  }

  openPopup(testId) {
    this.popupService.setIdTest(testId);
    this.popupService.setContext(this);
    this.popupService.openPopup();
  }

  resetFilter() {
    this.level = this.defaultLevel;
    this.refreshTestsList();
  }

  // API DELETE

  onDelete() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: { title: this.translate.getWord("delete.inProgress") },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const loadDialog = this.dialog.open(LoadDialogComponent, {
            disableClose: true,
            data: { title: this.translate.getWord("delete.inProgress") },
          });

          this.testService.delete(this.test).subscribe(
            () => {
              loadDialog.close();
              this.hasError = false;

              this.dialog
                .open(ValidErrorDialogComponent, {
                  data: {
                    title: this.translate.getWord("delete.valid"),
                    state: 1,
                  },
                })
                .afterClosed()
                .subscribe(() => this.router.navigate(["tests"]));
            },
            (error: HttpErrorResponse) => {
              loadDialog.close();
              this.error =
                error.error.message === undefined
                  ? this.translate.getWord("network.error")
                  : error.error.message;
              this.hasError = true;
            }
          );
        }
      });
  }
  user(user: any) {
    throw new Error("Method not implemented.");
  }

  loadLevels() {
    this.levelService.getList().subscribe((levels) => {
      this.levels = levels;
    });
  }
}