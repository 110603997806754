import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private apiUrl = '/PolymatheeWeb/email';// Remplacez par l'URL de votre API

  constructor(private http: HttpClient) { }

  sendResetPasswordEmail(email: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/send-reset-password-email`,email);
  }
}
