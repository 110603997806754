import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Question } from '../models/question.model';
import { Level } from '../models/level.model';
import { Technology } from '../models/technology.model';
import { TypeQuestion } from '../models/typeQuestion';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private static URL = '/PolymatheeWeb/questions';

  constructor(private http: HttpClient) {}

  getList(level: Level, technologies: Technology[]): Observable<Question[]> {
    let url = QuestionService.URL + '?';

    if (level) {
      url += '&level=' + level.id;
    }

    if (technologies) {
      technologies.forEach(technology => {
        url += '&technology=' + technology.id;
      });
    }

    return this.http.get<Question[]>(url)
      .pipe(map(questions => {
        questions.forEach((q, i, t) => t[i] = new Question(q));
        return questions;
      }));
  }

  getRandomList(levelsIds: number[], technologyId: number, duration: number): Observable<Question[]> {

    let url = QuestionService.URL + '/random?';
    if (levelsIds && levelsIds.length > 0) {
      url += '&levels=' + levelsIds;
    }
    if (technologyId) {
        url += '&technology=' + technologyId;
    }
    url += '&duration=' + duration;
    return this.http.get<Question[]>(url)
      .pipe(map(questions => {
        questions.forEach((q, i, t) => t[i] = new Question(q));
        return questions;
      }));
  }

  getById(id: number): Observable<Question> {
    return this.http.get<Question>(QuestionService.URL + '/' + id)
      .pipe(map(q => {
        return new Question(q);
      }));
  }

  save(question: Question) {
    return this.http.post<Question>(QuestionService.URL, question)
      .pipe(map(q => {
        return new Question(q);
    }));
  }

  update(question: Question) {
    return this.http.put<Question>(QuestionService.URL + '/' + question.id, question)
      .pipe(map(q => {
        return new Question(q);
    }));
  }

  delete(question: Question): Observable<Question> {
    return this.http.delete<Question>(QuestionService.URL + '/' + question.id);
  }

  getMaxDuration(technologyId: number, levelsIds: number[]): Observable<number> {
    let url = QuestionService.URL + '/max-duration?';
    if (technologyId) {
        url += '&technology=' + technologyId;
    }
    if (levelsIds && levelsIds.length > 0) {
      url += '&levels=' + levelsIds;
    }
    return this.http.get<number>(url);
  }
}
