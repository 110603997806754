import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  private title = '';

  constructor() {}

  getTitle(): string {
    return this.title;
  }

  setTitle(title: string) {
    this.title = title;
  }
}
