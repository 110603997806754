import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/models/user.model';
import { map } from 'rxjs/operators';
import { Technology } from '../models/technology.model';
import { Status } from '../models/status.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private static URL = '/PolymatheeWeb/users';

  constructor(private http: HttpClient) { }

  getById(id: number): Observable<User> {
    return this.http.get<User>(UserService.URL + '/' + id)
      .pipe(map(u => {
        return new User(u);
      }));
  }

  getList(): Observable<User[]> {
    return this.http.get<User[]>(UserService.URL)
      .pipe(map(users => {
        users.forEach((u, i, t) => t[i] = new User(u));
        return users;
      }));
  }

  getCandidatesList(status?: Status, mailValide?: boolean, freshness = 0, technologies?: Technology[], noteMin = 0): Observable<User[]> {
    let url = UserService.URL + '/candidates?';

    if (status) {
      url += '&status=' + status.id;
    }

    if (mailValide != null) {
      url += '&mailValid=' + (mailValide ? 'true' : 'false');
    }

    if (freshness > 0) {
      url += '&freshness=' + freshness;
    }

    if (technologies) {
      technologies.forEach(technology => {
        url += '&technology=' + technology.id;
      });
    }

    if (noteMin > 0) {
      url += '&noteMin=' + noteMin;
    }

    return this.http.get<User[]>(url)
      .pipe(map(users => {
        users.forEach((u, i, t) => t[i] = new User(u));
        return users;
      }));
  }

  save(user: User): Observable<User> {
    return this.http.post<User>(UserService.URL, user)
      .pipe(map(u => {
        return new User(u);
      }));
  }

  update(user: User) {
    return this.http.put(UserService.URL + '/' + user.id, user);
  }

  activationWithKey(key: string) {
    return this.http.post<string>(UserService.URL + '/activation', key);
  }

  validUser(user: User) {
    return this.http.post(UserService.URL + '/' + user.id + '/valid', '');
  }

  refuseUser(user: User) {
    return this.http.post(UserService.URL + '/' + user.id + '/refuse', '');
  }

  delete(user: User) {
    return this.http.delete(UserService.URL + '/' + user.id);
  }


  getRecruters(status?: Status, mailValide?: boolean, freshness = 1): Observable<User[]> {
    let url = UserService.URL + '/recruteurs?';

    if (status) {
      url += '&status=' + status.id;
    }

    if (mailValide != null) {
      url += '&mailValid=' + (mailValide ? 'true' : 'false');
    }

    if (freshness > 0) {
      url += '&freshness=' + freshness;
    }

    return this.http.get<User[]>(url)
      .pipe(map(users => {
        users.forEach((u, i, t) => t[i] = new User(u));
        return users;
      }));
  }

  getTopCandidates() {
    return this.http.get<User[]>(UserService.URL).pipe(
      map(users => {
        users.forEach(u => new User(u));
        let candidates = users.filter(element => element.profil.name === "CANDIDAT");
        let highMarks = {};
        candidates.forEach(candidat => {
          candidat.passages.forEach(passage => {
            passage.markByLanguage.forEach(mark => {
              let technologie = mark.technology;
              let markMax = mark.mark;
              if (markMax >= 75 && (!highMarks[technologie.name] || markMax > highMarks[technologie.name].mark)) {
                highMarks[technologie.name] = {
                  candidat: candidat.name && candidat.firstname ? `${candidat.name} ${candidat.firstname}` : null,
                  technologie: technologie.name,
                  mark: markMax
                };
              }
            });
          });
        });
        return Object.values(highMarks);
      })
    );
  }

}
