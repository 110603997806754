import { Question } from '../question.model';
import { Proposition } from '../proposition.model';
import { Exam } from '../exam.model';

export class AnswerSchema {
    id?: number;
    question?: Question;
    exam?: Exam;
    mark?: number;
    propositions?: Proposition[];
    code?: string;

    constructor(answer: AnswerSchema = {}) {
        this.id = answer.id;

        if (answer.question) {
            this.question = new Question(answer.question);
        }
        

        if (answer.exam) {
            this.exam = new Exam(answer.exam);
        }

        this.mark = answer.mark;

        if (answer.propositions) {
            answer.propositions.forEach((v, i, t) => t[i] = new Proposition(v));
            this.propositions = answer.propositions;
        } else {
            this.propositions = [];
        }


        this.code = answer.code;
    }
}
