import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SigninService } from '../signin/signin.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private signinService: SigninService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedRoles = route.data['roles'] as Array<string>;
    const user = this.signinService.getUser();
    if (!this.signinService.isAuth()) {
      this.router.navigate(['login'], {queryParams: {returnUrl: location.pathname + location.search}});
      return false;
    }
    else if (allowedRoles && !allowedRoles.includes(user.profil.name)) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
