import { ValidatorFn, AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export class CustomValidator {
  static passwordConfirm(equalControl: AbstractControl): ValidatorFn {
    let first = true;

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (first) {
        equalControl.valueChanges.subscribe(() => control.updateValueAndValidity());
        first = false;
      }

      if (control.value !== null && equalControl.value !== null
          && control.value !== equalControl.value) {
        return { notEqualComposant: true };
      }
      return null;
    };
  }

  static timeMinuteSecondCheck: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const timeMinutes = control.get('timeMinutes').value;
    const timeSeconds = control.get('timeSeconds').value;

    return timeMinutes * 60 + timeSeconds <= 0 ? { time: true } : null;
  }
}
