import { Technology } from '../technology.model';

export class MarkTechnologySchema {
    technology?: Technology;
    mark?: number;

    constructor(answer: MarkTechnologySchema = {}) {
        if (answer.technology) {
            this.technology = new Technology(answer.technology);
        }

        this.mark = answer.mark;
    }
}
