import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProfessionalExperience } from '../models/professionalExperience.model';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalExperienceService {

  constructor(private http: HttpClient) {}

  getList(): Observable<ProfessionalExperience[]> {
    return this.http.get<ProfessionalExperience[]>('/PolymatheeWeb/professionalExperiences')
      .pipe(map(professionalExperiences => {
        professionalExperiences.forEach((pe, i, t) => t[i] = new ProfessionalExperience(pe));
        return professionalExperiences;
      }));
  }
}
