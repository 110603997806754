import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { User } from 'src/app/models/user.model';


@Component({
  selector: 'app-profil-change-password',
  templateUrl: './profil.change.password.component.html',
  styleUrls: ['./profil.change.password.component.scss']
})
export class ProfilChangePasswordComponent {
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
  id: number; 
  user: User;

  
  constructor(private apiService: ApiService, private signInService: SigninService, private route: ActivatedRoute , private router: Router) {
    this.id = 3;
    //console.log('id', this.id)
  }
 
  changePassword() {
    let user = this.signInService.getUser();
      if (this.newPassword === this.confirmPassword) {
        this.signInService.logoutWithServerNotified();
        this.apiService.changePasswordProfilMenu(user.id, this.newPassword, this.oldPassword).subscribe(
          (response) => console.log('Mot de passe changé avec succès.', response)
        );
      } else {
        console.error('Les mots de passe ne correspondent pas.');
      }
      this.router.navigate(['login']);
  }
}