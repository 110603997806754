import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface LoadDialogData {
  title: string;
}

@Component({
  selector: 'app-load-dialog',
  templateUrl: './load-dialog.component.html',
  styleUrls: ['./load-dialog.component.scss']
})
export class LoadDialogComponent {

  constructor(public dialogRef: MatDialogRef<LoadDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: LoadDialogData) { }

}
