import { Component } from '@angular/core';
import { Menu } from '../models/menu.model';
import { SigninService } from '../auth/signin/signin.service';
import { LanguageService } from '../language/language.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  title = 'Polymathée';

  constructor(private signinService: SigninService, languageService: LanguageService) {
    languageService.addWebLanguageChangeListener(() => this.signinService.reloadMenu());
  }

  getMenus() {
    return this.signinService.isAuth() ? this.signinService.getUser().profil.menus : Menu[0];
  }
}
