import { Injectable } from '@angular/core';
import { StatusExam } from '../models/status-exam.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatusExamService {

  static AFFECTED = new StatusExam({id: 'AFFECTED'});
  static IN_PROGRESS = new StatusExam({id: 'IN_PROGRESS'});
  static FINISHED = new StatusExam({id: 'FINISHED'});
  static FRAUDED = new StatusExam({id: 'FRAUDED'});

  constructor(private http: HttpClient) {}

  getList(): Observable<StatusExam[]> {
    return this.http.get<StatusExam[]>('/PolymatheeWeb/statusExams')
      .pipe(map(statusExams => {
        statusExams.forEach((tq, i, t) => t[i] = new StatusExam(tq));
        return statusExams;
      }));
  }
}
