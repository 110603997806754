import { ProfessionalExperienceSchema } from './interface/professionalExperience.model';

export class ProfessionalExperience extends ProfessionalExperienceSchema {

    equals(professionalExperience: ProfessionalExperience): boolean {
        return this.id === professionalExperience.id;
    }
    getName(): string {
        return this.name  ? `${this.name} ` : null;
    }
}
