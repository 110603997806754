import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../language/language.service';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  constructor(private translate: LanguageService) {}

  transform(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    let time = '';
    if (minutes)
      time += `${minutes} minutes `;

    time += `${remainingSeconds} ` + this.translate.getWord('word.seconds');
    return time;
  }
}