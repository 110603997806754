import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Profil } from '../models/profil.model';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  static ADMIN = new Profil({id: 1});
  static RECRUITER = new Profil({id: 2});
  static CANDIDATE = new Profil({id: 3});

  constructor(private http: HttpClient) {}

  getList(): Observable<Profil[]> {
    return this.http.get<Profil[]>('/PolymatheeWeb/profils')
      .pipe(map(profils => {
        profils.forEach((tq, i, t) => t[i] = new Profil(tq));
        return profils;
      }));
  }
}
