import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { Technology } from 'src/app/models/technology.model';
import { TechnologyService } from 'src/app/services/technology.service';
import { ResponsiveService } from 'src/app/tools/responsive.service';

@Component({
  selector: 'app-list-technology',
  templateUrl: './list-technology.component.html',
  styleUrls: ['./list-technology.component.scss']
})
export class ListTechnologyComponent implements OnInit {

  private technologies: Technology[];
  private technology: Technology;
  private dataSource: MatTableDataSource<Technology>;
  private technologiesRequest: Subscription;
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dialog: any;
  translate: any;
  userService: any;
  hasError: boolean;
  router: any;
  error: any;
  
  constructor(private technologieService: TechnologyService,private responsiveService: ResponsiveService) { 
    this.technologies = [];
    this.dataSource = new MatTableDataSource(this.technologies);

    // Pour refrechir la liste des technologies
    this.refreshTechnologiesList();
  }

  ngOnInit() {

    // Appel aux paginations
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Filtrer la technologie avec son nom
    this.dataSource.filterPredicate = (technology: Technology, filter: string) => {
      filter = filter.trim().toLowerCase();
      const name = technology.name.toLowerCase();
      if (name.search(filter) >= 0) {
        return true;
      }
      return false;
    };
  }

  getTechnologiesDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? [ 'name', 'action']
      : [ 'name', 'action'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

   // Pour récupérer la liste des technologies
  refreshTechnologiesList(){
    if (this.technologiesRequest) {
      this.technologiesRequest.unsubscribe();
    }

    this.technologiesRequest = this.technologieService.getList()
        .subscribe(technologies => {
          this.technologies.splice(0, this.technologies.length);
          this.technologies.push(...technologies);
          this.dataSource.filter = '';
      });
  }

}
