import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Technology } from '../models/technology.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TechnologyService {

  private static URL = '/PolymatheeWeb/technologies';

  constructor(private http: HttpClient) {}

  getList(): Observable<Technology[]> {
    return this.http.get<Technology[]>(TechnologyService.URL)
      .pipe(map(technologies => {
        technologies.forEach((tec, i, t) => t[i] = new Technology(tec));
        return technologies;
      }));
  }

  getById(id: number): Observable<Technology> {
    return this.http.get<Technology>(TechnologyService.URL + '/' + id)
      .pipe(map(q => {
        return new Technology(q);
      }));
  }

  save(technology: Technology): Observable<Technology> {
    return this.http.post<Technology>(TechnologyService.URL, technology)
      .pipe(map(q => {
        return new Technology(q);
    }));
  }

  update(technology: Technology): Observable<Technology> {
    return this.http.put<Technology>(TechnologyService.URL + '/' + technology.id, technology)
      .pipe(map(q => {
        return new Technology(q);
    }));
  }

  delete(technology: Technology): Observable<Technology> {
    
    return this.http.delete<Technology>(TechnologyService.URL + '/' + technology.id);
  }
}
