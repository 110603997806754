import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SigninService } from '../auth/signin/signin.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHTTPInterceptor implements HttpInterceptor {

    constructor(private signinService: SigninService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    this.logout();
                    console.log('Connexion requis');
                    break;

                case 500:
                    console.log('Erreur serveur');
                    break;

                case 504:
                    this.logout();
                    console.log('Serveur indisponible');
            }

            return throwError(err);
        }));
    }

    logout() {
        this.signinService.logout();

        if (location.pathname !== '/login') {
            this.router.navigate(['login'], {queryParams: {returnUrl: location.pathname + location.search}});
        }
    }
}
