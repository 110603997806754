import { Component } from '@angular/core';
import { SigninService } from '../../auth/signin/signin.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-dash-board-candidate',
  templateUrl: './dash-board-candidate.component.html',
  styleUrls: ['./dash-board-candidate.component.scss']
})
export class DashBoardCandidateComponent {

  constructor(private signinService: SigninService) {}

  getUser(): User {
    return this.signinService.getUser();
  }
}
