import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl =  '/PolymatheeWeb/change'; 

  constructor(private http: HttpClient) { }


  changePassword(id: number, newPassword: string): Observable<any> {
  
  const changePass = {
    "id": id,
    "newPassword": newPassword
    }
    return this.http.post<any>(`${this.apiUrl}/change-password`, changePass );
  }

  changePasswordProfilMenu(id: number, newPassword: string, oldPassword: string): Observable<any> {
  
    const changePass = {
      "id": id,
      "newPassword": newPassword,
      "oldPassword": oldPassword
      }
      return this.http.post<any>(`${this.apiUrl}/change-password-in-profil-menu`, changePass );
    }

}


