import { Component, ViewChild, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Answer } from 'src/app/models/answer.model';
import { CandidateAnswersService } from 'src/app/services/CandidateAnswers.service';
import { Question } from 'src/app/models/question.model';
import { Subscription } from 'rxjs';
import { QuestionService } from 'src/app/services/question.service';
import { Level } from 'src/app/models/level.model';
import { Technology } from 'src/app/models/technology.model';
import { Proposition } from 'src/app/models/proposition.model';
import { Exam } from 'src/app/models/exam.model';
import { ExamService } from 'src/app/services/exam.service';
import { Test } from 'src/app/models/test.model';
import { StatusExam } from 'src/app/models/status-exam.model';

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss']
})
export class AnswersComponent implements OnInit {

  private answerRequest: Subscription;
  public defaultQuestion = new Question();

  private answers: Answer[];
  answer: Answer;
  
  private statutExams: StatusExam [][];
  private test: Test;
  private exam: Exam; 
  private exams:Exam [];
  public defaultExam = new Exam();
  

  private proposition: Proposition;
  private propositions: Proposition [];
  public defaultProposition = new Proposition();

  private level: Level;
  private technologies: Technology[];

  dataSource: MatTableDataSource<Answer>;

  // Filtres
  questions: Question[];
  question: Question;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  dialog: any;
  translate: any;
  userService: any;
  hasError: boolean;
  router: any;
  error: any;
  constructor(private questionService: QuestionService, private responsiveService: ResponsiveService,
    private answerService: CandidateAnswersService,private examService: ExamService) {
    this.answers = [];
    this.dataSource = new MatTableDataSource(this.answers);

    this.question = this.defaultQuestion;
    this.exam = this.defaultExam;

    this.examService.getList(this.test,this.statutExams).subscribe(exams => {
      this.exams = exams;
    });
  
    this.questionService.getList(this.level,this.technologies).subscribe(questions => {
      this.questions = questions;
    });

    this.refreshAnswersList();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  getAnswersDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? [ 'Exam','question','type','MarkQuestion','technology','level','mark','Proposition']
      : [ 'Exam','question', 'type','MarkQuestion','technology','level','mark','Proposition'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  refreshAnswersList() {
    if (this.answerRequest) {
      this.answerRequest.unsubscribe();
    }

    this.answerRequest = this.answerService.getList(
      this.exam !== this.defaultExam ? this.exam : null,  this.question !== this.defaultQuestion? this.question : null,
      this.proposition !== this.defaultProposition? this.proposition : null )
      .subscribe(answers => {
        this.answers.splice(0, this.answers.length);
        this.answers.push(...answers);
        this.dataSource.filter = '';
    });
  }

  user() {
    throw new Error("Method not implemented.");
  }



}
