import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { User } from "src/app/models/user.model";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RecruiterService {
  private static URL = "/PolymatheeWeb/recruteurs";

  constructor(private http: HttpClient) {}

  saveRecruteur(user: User): Observable<User> {
    return this.http.post<User>(RecruiterService.URL, user).pipe(
      map((u) => {
        return new User(u);
      })
    );
  }

  suspendRecruiter(user: User): Observable<User> {
    return this.http.put<User>(RecruiterService.URL + '/' + user.id + '/status', user);
  }
}
