import { Router } from '@angular/router';
import { SigninService } from '../auth/signin/signin.service';
import { ResponsiveService } from './responsive.service';

export class SidenavService {

  private opened = true;
  private mode = '';

  constructor(router: Router, private signinService: SigninService, private responsiveService: ResponsiveService) {
    router.events.subscribe((val) => {
      if (responsiveService.isOnTabletScreen()) {
        this.setOpened(false);
      }
    });
  }

  getModeMenu() {
    let mode = 'side';
    const portableScreen = this.responsiveService.isOnTabletScreen();

    mode = portableScreen ? 'over' : 'side';

    if (mode !== this.mode) {
      this.mode = mode;

      this.opened = !portableScreen;
    }

    return this.mode;
  }

  isOpened() {
    return this.signinService.isAuth() && this.opened;
  }

  setOpened(opened) {
    this.opened = opened;
  }

  toggleMenu() {
    this.opened = !this.opened;
  }

}
