import { Injectable } from '@angular/core';
import { Status } from '../models/status.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StatusService {

  static WAITING = new Status({id: 1});
  static VALIDATED = new Status({id: 2});
  static SUSPENDED = new Status({id: 3});
  static REFUSED = new Status({id: 4});

  constructor(private http: HttpClient) {}

  getList(): Observable<Status[]> {
    return this.http.get<Status[]>('/PolymatheeWeb/status')
      .pipe(map(levels => {
        levels.forEach((l, i, t) => t[i] = new Status(l));
        return levels;
      }));
  }
}
