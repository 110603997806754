export class ResponsiveService {

  constructor() {}

  isOnTabletScreen() {
    return window.innerWidth < 1000;
  }

  isOnPortableScreen() {
    return window.innerWidth < 600;
  }
}
