import { Menu } from '../menu.model';

export class MenuSchema {
    id?: number;
    name?: string;
    rank?: number;
    url?: string;
    icon?: string;
    childrensMenu?: Menu[];

    constructor(menu: MenuSchema = {}) {
        this.id = menu.id;
        this.name = menu.name;
        this.rank = menu.rank;
        this.url = menu.url;
        this.icon = menu.icon;

        if (menu.childrensMenu) {
            menu.childrensMenu.forEach((m, i, tab) => tab[i] = new Menu(m));
            this.childrensMenu = menu.childrensMenu;
        } else {
            this.childrensMenu = [];
        }
    }
}
