import { Component, OnInit, ViewChild } from '@angular/core';
import { TestService } from 'src/app/services/test.service';
import { ResponsiveService } from 'src/app/tools/responsive.service';
import { Test } from 'src/app/models/test.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SchoolLevel } from 'src/app/models/schoolLevel.model';
import { ProfessionalExperience } from 'src/app/models/professionalExperience.model';
import { Exam } from 'src/app/models/exam.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { LanguageService } from 'src/app/language/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolLevelService } from 'src/app/services/schoolLevel.service';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { ValidErrorDialogComponent } from 'src/app/dialog/valid-error-dialog/valid-error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Level } from 'src/app/models/level.model';
import { LevelService } from 'src/app/services/level.service';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';
import { NgWizardService, StepChangedArgs } from 'ng-wizard';
import { Technology } from 'src/app/models/technology.model';
import { TypeQuestion } from 'src/app/models/typeQuestion';
import { TechnologyService } from 'src/app/services/technology.service';
import { TypeQuestionService } from 'src/app/services/typequestion.service';
import { Question } from 'src/app/models/question.model';
import { QuestionService } from 'src/app/services/question.service';

@Component({
  selector: 'app-update-test',
  templateUrl: './update-test.component.html',
  styleUrls: ['./update-test.component.scss']
})
export class UpdateTestComponent implements OnInit {

  // Appel le model test
  test: Test;
  // Le formulaire du test "HTML"
  nameLevelFormGroup: FormGroup;
  // Le message d'erreur
  error: string;
  hasError: boolean;
  // Level
  levels: Level[];
  technos: Technology[];
  typeQuestions: TypeQuestion[];
  loading = true;
  errorMessage = "";
  // Constrecteur 
  levelTitle: string;
  technologiesTitle: string;
  questionsTitle: string;
  summaryTitle: string;
  technosFormGroup: FormGroup;
  questions: Question[];
  loadedQuestion = false;
  dataSource: MatTableDataSource<Question>;
  constructor(private testService: TestService, private formBuilder: FormBuilder,public dialog: MatDialog, private translate: LanguageService,private route: ActivatedRoute, private router: Router,
              private levelService: LevelService, private userService: UserService, private stepperService: NgWizardService, private technologyService: TechnologyService,
              private typeQuestionService: TypeQuestionService, private responsiveService: ResponsiveService, private questionService: QuestionService) {

              this.technologyService.getList().subscribe(technos => {
                this.technos = technos;
                // set previously selected technos

              });
              // Pour la tradiction 
              translate.addWebLanguageChangeListener(() => this.refreshSelect());
              // Selectionner les inputs
              this.refreshSelect();

              translate.addWebLanguageChangeListener(() => this.onLanguageChange());
              this.onLanguageChange();
  }

  
  ngOnInit()  {
    this.test = new Test();
    this.test.id = this.route.snapshot.params.id;
    // Validations
    this.nameLevelFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      level: ['', Validators.required]
    });
    this.technosFormGroup = this.formBuilder.group({
      technos: ['', Validators.required]
    });
    this.refreshTest();
  }
  
  backToPage() {
    if (history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }
  
  // Appel à la methode submit
  onSubmit() {
    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('update.inProgress')}
    });
    this.test.name = this.nameLevelFormGroup.get('name').value;
    let level = new Level();
    if(this.getLevel()){
      level = this.levels.find(l => l.name == this.getLevel())
      
    }
    this.test.level = level;
    this.test.questions = this.questions.filter((question) => question.checkedToAssignedTest === true);

    // // appel au service update 
    this.testService.update(this.test).subscribe(() => {
      dialog.close();
      this.hasError = false;
      this.dialog.open(ValidErrorDialogComponent, {
        data: {title: this.translate.getWord('update.valid'), state: 1}
      });
    // Retour à la liste des tests
      this.router.navigate(['tests']);
    },
    (error: HttpErrorResponse) => {
      dialog.close();
      this.error = error.error.message === undefined ? this.translate.getWord('network.error') : error.error.message;
      this.hasError = true;
    });
  }
  
  // Appel au service getList pour récuéper la liste des Levels
  refreshSelect() {
    this.levelService.getList().subscribe(levels => {
      this.levels = levels;
    });
  }

  refreshTest() {
    this.testService.getById(this.test.id).subscribe(test => {
      this.test = test;
      this.nameLevelFormGroup.get('name').setValue(this.test.name);
      this.nameLevelFormGroup.get('level').setValue(this.test.level.name);
      let technologies = [];
      let uniqueTechnologies = [];
      if(this.test.questions){
        technologies = this.test.questions.map(question => question.technology.name);
        uniqueTechnologies = [...new Set(technologies)];
      }
      this.technosFormGroup.get('technos').setValue(uniqueTechnologies);
    }, (error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
        case 404:
          this.router.navigate(['tests']);
      }
    });
  }

  isSameLevel(o1: Level, o2: Level) {
    if (o1 == null || o2 == null) {
      return false;
    }

    return o1.equals(o2);
  }
  
// API DELETE 

onDelete() {
  
  this.dialog.open(ConfirmationDialogComponent, {
    disableClose: true,
    data: {title: this.translate.getWord('test.deletequestion')}
  })
  .afterClosed().subscribe(result => {
    if (result) {
      const loadDialog = this.dialog.open(LoadDialogComponent, {
        disableClose: true,
        data: {title: this.translate.getWord('delete.inProgress')}
      });

      this.testService.delete(this.test).subscribe(() => {
        loadDialog.close();
        this.hasError = false;

        this.dialog.open(ValidErrorDialogComponent, {
          data: {title: this.translate.getWord('delete.valid'), state: 1}
        })
        .afterClosed().subscribe(() => this.router.navigate(['tests']));
      
      }, (error: HttpErrorResponse) => {

        loadDialog.close();
        switch (error.status) {
          case 500:
            case 400:
            case 404:
            this.dialog.open(ValidErrorDialogComponent, {
              data: {title: this.translate.getWord('test.deleteerror')}
            })
        }
      });
    }
  });
}
  user(user: any) {
    throw new Error("Method not implemented.");
  }

  goNextStep() {
    this.stepperService.next();
  }

  onStepperSelectionChange(event: StepChangedArgs) {
    // if (event.step.index === 3) {
      // this.randomQuestions();
    // } else if (this.questionsListSub != null && !this.questionsListSub.closed) {
      // this.questionsListSub.unsubscribe();
    // }
    if(event.position == "final"){
      this.getQuestions();
    }
  }

  onLanguageChange() {
    this.levelTitle = this.translate.getWord('word.level');
    this.technologiesTitle = this.translate.getWord('word.technologies');
    this.questionsTitle = this.translate.getWord('word.questions');
    this.summaryTitle = this.translate.getWord('word.recapitulatif');
  }


  onLanguageSelectionChange() {
    const technosChoice: Technology[] = this.technosFormGroup.get('technos').value;

    this.typeQuestionService.getList().subscribe(typeQuestions => {
      this.typeQuestions = typeQuestions;
    });
  }

  getTechnologie(name: string) {
    return this.technos.find(t => t.name === name);
  }

  getQuestionsDisplayedColumns(): string[] {
    return this.responsiveService.isOnPortableScreen()
      ? ['id', 'question', 'technology', 'type','time']
      : ['id', 'question', 'technology', 'type','time'];
  }

  getName() {
    return this.nameLevelFormGroup.get('name').value;
  }

  getLevel() {
    return this.nameLevelFormGroup.get('level').value;
  }

  isSameTypeQuestion(o1: TypeQuestion, o2: TypeQuestion) {
    return o1.equals(o2);
  }

  getQuestions() {
    const technologies: Technology[] = [];
    if(this.technosFormGroup.get('technos').value){
      for (let technoName of this.technosFormGroup.get('technos').value){
        let technologie = this.getTechnologie(technoName);
        technologies.push(technologie);
      }
    }

    // Setting the level of the questions
    let level = new Level();
    if(this.getLevel()){
      level = this.levels.find(l => l.name == this.getLevel())
      
    }
    this.questionService.getList(level, technologies).subscribe(questions => {
      this.questions = questions;
      this.dataSource = new MatTableDataSource(this.questions);

      //Setting th old tests
      for(let question of this.questions){
        if(this.test.questions && this.test.questions.length > 0){
          let oldQuestionsIds = this.test.questions.map(element => element.id);
          if(oldQuestionsIds.includes(question.id)){
            question.checkedToAssignedTest = true;
          }
        }
      }
    }, (error: HttpErrorResponse) => {
      this.stepperService.previous();
      this.dialog.open(ValidErrorDialogComponent, {
        data: {state: -1, title: 'Erreur', description: error.error.message }
      });
    });
  }

  goPreviousStep() {
    this.stepperService.previous();
  }

}
