import { Component, OnInit, ViewChild } from '@angular/core';
import { SigninService } from '../../auth/signin/signin.service';
import { User } from '../../models/user.model';
import { ProfilService } from 'src/app/services/profil.service';
import { Profil } from 'src/app/models/profil.model';
import { UserService } from 'src/app/services/user.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';

export interface Candidate {
  id: number;
  name: string;
  technology: string;
  mark: number;
}

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {

  dataSource: MatTableDataSource<Candidate>;
  displayedColumns: string[] = ['technology', 'name', 'mark'];
  topCandidates: Candidate[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private signinService: SigninService, private userService: UserService) {
    this.dataSource = new MatTableDataSource(this.topCandidates);
  }

  ngOnInit(): void {
    this.userService.getTopCandidates().subscribe(
      (topCandidates: Candidate[]) => {
        this.topCandidates = topCandidates;
        this.dataSource.data = this.topCandidates; // Mettre à jour le dataSource avec les nouvelles données
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log('Top candidates:', this.topCandidates);
      },
      error => {
        console.error('Erreur lors de la récupération des meilleurs candidats:', error);
      }
    );
  }

  getUser(): User {
    return this.signinService.getUser();
  }

  isCandidate(): boolean {
    return this.checkGroup(ProfilService.CANDIDATE);
  }

  isRecruiter(): boolean {
    return this.checkGroup(ProfilService.RECRUITER);
  }

  isAdmin(): boolean {
    return this.checkGroup(ProfilService.ADMIN);
  }

  checkGroup(profil: Profil): boolean {
    return this.getUser().profil.equals(profil);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getTechnologiesDisplayedColumns(): string[] {
    return this.displayedColumns;
  }
}
