import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthGuard } from './auth/guards/auth-guard.service';
import { DashBoardComponent } from './home/dash-board/dash-board.component';
import { UserActivationComponent } from './users/user-activation/user-activation.component';
import { NoAuthGuard } from './auth/guards/no-auth-guard.service';
import { WaitCandidateComponent } from './users/wait-candidate/wait-candidate.component';
import { CandidateComponent } from './users/candidate/candidate.component';
import { RegisterCandidateComponent } from './users/register-candidate/register-candidate.component';
import { ViewUserComponent } from './users/view-user/view-user.component';
import { ViewQuestionnaireComponent } from './questions/view-question/view-question.component';
import { CreateQuestionComponent } from './questions/create-question/create-question.component';
import { ListQuestionComponent } from './questions/list-question/list-question.component';
import { ListTestsComponent } from './test/list-test/list-test.component';
import { AddTestComponent } from './test/add-test/add-test.component';
import { PassExamComponent } from './exam/pass-exam/pass-exam.component';
import { ListExamComponent } from './exam/list-exam/list-exam/list-exam.component';
import { UpdateTestComponent } from './test/update-test/update-test.component';
import { ListTechnologyComponent } from './technology/list-technology/list-technology.component';
import { UpdateTechnologyComponent } from './technology/update-technology/update-technology.component';
import { AddTechnologyComponent } from './technology/add-technology/add-technology.component';
import { RecruteurComponent } from './users/recruteur/recruteur.component';
import { AddRecruteurComponent } from './users/add-recruteur/add-recruteur.component';
import { UpdateRecruteurComponent } from './users/update-recruteur/update-recruteur.component';
import { AnswersComponent } from './answer/answers/answers.component';
import { ExamDetailsComponent } from './exam-details/exam-details.component';
import { ExamGuard } from './services/guards/exam-guard.service';
import { CandidateStatsComponent } from './users/candidate-stats/candidate.stats.component'; /* FDH NEW */
import { ResetPasswordComponent } from './auth/reset-password/reset.password.component';
import { ChangePasswordComponent } from './auth/change-password/change.password.component';
import { ProfilChangePasswordComponent } from './profil/password/profil.change.password.component';

const routes: Routes = [
  { path: 'home', canActivate: [AuthGuard], component: DashBoardComponent },
  { path: 'login', canActivate: [NoAuthGuard], component: SigninComponent },
  { path: 'activation', canActivate: [NoAuthGuard], component: UserActivationComponent },
  { path: 'users', canActivate: [AuthGuard], component: CandidateComponent },
  { path: 'profil', component: ProfilChangePasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'change-password/:id', canActivate: [NoAuthGuard], component : ChangePasswordComponent},


  { path: 'candidates', data: { roles: ['RECRUTEUR']}, children: [
    { path: '', canActivate: [AuthGuard], component: CandidateComponent },
    { path: 'register', canActivate: [AuthGuard], component: RegisterCandidateComponent },
    { path: 'wait', canActivate: [AuthGuard], component: WaitCandidateComponent },
    { path: 'stats/:id', canActivate: [AuthGuard], component: CandidateStatsComponent },   
    { path: ':id/informations', canActivate: [AuthGuard], component: ViewUserComponent },
    { path: ':id/tests', canActivate: [AuthGuard], component: ViewUserComponent }
  ]},

  { path: 'questions', data: { roles: ['ADMINISTRATEUR']}, children: [
    { path: '', canActivate: [AuthGuard], component: ListQuestionComponent },
    { path: 'new', canActivate: [AuthGuard], component: CreateQuestionComponent },
    { path: ':id', canActivate: [AuthGuard], component: ViewQuestionnaireComponent },
  ]},

  { path: 'tests', data: { roles: ['CANDIDAT', 'RECRUTEUR']}, children: [
    { path: '', canActivate: [AuthGuard], component: ListTestsComponent },
    { path: 'add', canActivate: [AuthGuard], component: AddTestComponent },
    { path: 'pass/:id', canActivate: [AuthGuard], component: PassExamComponent, canDeactivate: [ExamGuard] },
    { path: ':id', canActivate: [AuthGuard], component: UpdateTestComponent },
 ]},

 { path: 'technologies', data: { roles: ['ADMINISTRATEUR']}, children: [
  { path: '', canActivate: [AuthGuard], component: ListTechnologyComponent },
  { path: 'add', canActivate: [AuthGuard], component: AddTechnologyComponent },
  { path: ':id', canActivate: [AuthGuard], component: UpdateTechnologyComponent },

]},

{ path: 'recruiters', data: { roles: ['ADMINISTRATEUR']}, children: [
  { path: '', canActivate: [AuthGuard], component: RecruteurComponent } ,
  { path: 'add', canActivate: [AuthGuard], component: AddRecruteurComponent },
  { path: ':id', canActivate: [AuthGuard], component: UpdateRecruteurComponent },
]},

{ path: 'answers', children: [
  { path: '', canActivate: [AuthGuard], component: AnswersComponent }

]},

 { path: 'exams', data: { roles: ['RECRUTEUR']}, children: [
  { path: '', canActivate: [AuthGuard], component: ListExamComponent },
  { path: ':id', canActivate: [AuthGuard], component: ExamDetailsComponent },
 
]},
  { path: 'level', canActivate: [AuthGuard], component: DashBoardComponent },
  { path: 'technology', canActivate: [AuthGuard], component: DashBoardComponent },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
