import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface ValidErrorData {
  title: string;
  description: string;
  state: number;
}

@Component({
  selector: 'app-valid-error-dialog',
  templateUrl: './valid-error-dialog.component.html',
  styleUrls: ['./valid-error-dialog.component.scss']
})
export class ValidErrorDialogComponent {

  constructor(public dialogRef: MatDialogRef<ValidErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ValidErrorData) {}

}
