import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SigninService } from './signin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { LoadDialogComponent } from 'src/app/dialog/load-dialog/load-dialog.component';
import { LanguageService } from 'src/app/language/language.service';
import { SidenavService } from 'src/app/tools/sidenav.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  cnxForm: FormGroup;
  returnUrl: string;
  error: string;
  hasError: boolean;

  constructor(private formBuilder: FormBuilder, private signinService: SigninService, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog, private translate: LanguageService,
              private sidenavService: SidenavService) {}

  ngOnInit() {
    this.cnxForm = this.formBuilder.group({
      mail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    // Récupère l'url de la page précédente (page d'accueil par défaut)
    const requestConnexion = this.route.snapshot.queryParams.returnUrl && this.route.snapshot.queryParams.returnUrl !== '/login';
    this.returnUrl = requestConnexion ? this.route.snapshot.queryParams.returnUrl : 'home';

    if (requestConnexion) {
      this.error = this.translate.getWord('network.forbidden');
    }
  }

  onSubmit() {
    const mail = this.cnxForm.get('mail').value;
    const passwordComponent = this.cnxForm.get('password');
    const password = passwordComponent.value;

    const dialog = this.dialog.open(LoadDialogComponent, {
      disableClose: true,
      data: {title: this.translate.getWord('login.inProgress')}
    });

    this.signinService.signin(mail, password)
      .subscribe(() => {
          dialog.close();
          this.hasError = false;
          this.router.navigate([this.returnUrl]);
          this.sidenavService.setOpened(true);
        },
        (error: HttpErrorResponse) => {
          dialog.close();
          passwordComponent.setValue('');
          this.error = error.error === undefined ? this.translate.getWord('network.error') : error.error.message;
          this.hasError = true;
        });
  }

}
